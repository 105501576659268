import React, {useEffect, useState, useCallback} from 'react';
import { useQuery } from 'react-query'
import * as api from "../helpers/api";
import axios from 'axios';
import Navbar from './navbar.component';
import UpdateUnitsOwned from './update-units-owned.component';
import UpdateUpgradesOwned from './update-upgrades-owned.component';
import "../styles/profile.css";
import Amazon from '../images/Amazon_icon.svg.png'
import MiniatureMarket from '../images/mm logo.png'
import ImageById from './image-by-id.component';
import getTranslation from '../helpers/getTranslation';

const factions = [
    {
        id: 3,
        name: 'Neutral'
    },
    {
        id: 1,
        name: 'Rebel'
    },
    {
        id: 2,
        name: 'Empire'
    },
    {
        id: 4,
        name: 'Galactic Republic'
    },
    {
        id: 5,
        name: 'Separatist'
    },
    {
        id: 6,
        name: 'Mercenary'
    }
]

const Collection = () => {
    const userPacks = useQuery('userPacks', api.fetchUserPacks).data
    const [changes, setChanges ] = useState([])
    const [ pack, setPack ] = useState(-1)
    const [ units, setUnits ] = useState([])
    const [ upgrades, setUpgrades ] = useState([])
    const [ commands, setCommands ] = useState([])
    const [ showUnits, setShowUnits ] = useState(false)
    const [ showUpgrades, setShowUpgrades ] = useState(false)
    const [ packs, setPacks ] = useState([])
    // const packs = useQuery('packs', api.fetchPacks).data
    const updatePack = useCallback((e) => {
        if (parseInt(e.target.value) < 100) {
            var upd = [...changes.filter(c => c.pack !== e.target.name)]
            upd.push({pack: e.target.name, quantity: e.target.value})
            setChanges(upd)
        } else {
            var upd2 = [...changes]
            upd2.push({pack: e.target.name, quantity: 99})
            setChanges(upd2)
        }
    }, [changes])
    const commitChanges = useCallback((e) => {
        e.preventDefault()
        axios.post('/api/update-collection', changes)
        .then(() => {
            window.location="/collection"
        })
        .catch(r => {
            console.log(r)
        })
    }, [changes])
    useEffect(() => {
       if(pack != -1){
           axios.get('/api/pack/' + pack)
           .then(r => {
               setUnits(r.data.units)
               setUpgrades(r.data.upgrades)
               setCommands(r.data.commands)
           })
       } else{
           setUnits([])
           setUpgrades([])
           setCommands([])
       }
    }, [pack])

    useEffect(() => {
        axios.get('/api/packs')
        .then(r => setPacks(r.data))
    }, [])
    return(
        <div>
        <Navbar />
        <br /><br />
        <div style={{position: 'relative'}} className="page-background">
            <div className="collection-page">
                <div className="header" style={{marginBottom: '12px'}}>{getTranslation('updateCollection')}</div>
                <div className="sub-header" style={{marginBottom: '12px'}}>{getTranslation('clickPack')}</div>
                <div className="sub-header"><img style={{height: '28px'}} src={Amazon} /> {getTranslation('affiliate')}</div>
                <br />
                <div style={{textAlign: 'center'}}><button className="collection-pack-name" onClick={() => setShowUnits(true)}>{getTranslation('adjustUnits')}</button></div>
                <div style={{textAlign: 'center'}}><button className="collection-pack-name" onClick={() => setShowUpgrades(true)}>{getTranslation('adjustUpgrades')}</button></div>
                {showUnits && <UpdateUnitsOwned setShowUnits={setShowUnits} />}
                {showUpgrades && <UpdateUpgradesOwned setShowUpgrades={setShowUpgrades} />}
                <br /><br />
            <div>
            <div>
            <div className="collection">
            {factions.map(f =>
                
            <div key={f.id} className="collection-section">
            <div className="collection-faction">{f.name}</div>
                {packs && userPacks && packs.filter(pk => pk.faction_fkey == f.id).map(p => 
                    <Pack key={p.id} pack={p} setPack={setPack} userPacks={userPacks} updatePack={updatePack} />
                )}
                </div>
            )}
            </div>
            </div>
            <div style={{textAlign: 'center'}}>
            <input onClick={commitChanges} type="submit" />
            </div>
        </div>
        </div> 
        {pack != -1 &&
        <div className="pack-info">
            <div className="header">{packs.find(p => p.id == pack).name}</div>
            <div className="close-button" onClick={() => setPack(-1)}>x</div>
            {units.map(u => <span key={u.id} className="pack-item-wrapper"><ImageById id={u.id} version={u.image_version} type='unit' alt={u.name} /><div className="pack-item-quantity">x{u.quantity}</div></span>)}<br />
            {upgrades.filter(u => !u.hide).map(u => <span key={u.id} className="pack-item-wrapper"><ImageById type='upgrade' id={u.id} alt={u.name} version={u.image_version} /><div className="pack-item-quantity">x{u.quantity}</div></span>)}<br />
            {commands.map(c => <ImageById type='command' id={c.id} alt={c.name} version={c.image_version} />)}
        
        </div>
        }
        </div>
       
        </div>
    )
}

export default Collection;

function Pack({pack, setPack, userPacks, updatePack}){
    return (
        <div key={pack.id} className="collection-wrapper">
            <div>
                <button className="collection-pack-name" onClick={() => setPack(pack.id)}>{pack.name}</button> 
            </div>
            <div style={{display: 'flex'}}>
                <input name={pack.id} style={{width: '30px', marginRight: '6px', fontSize: '18px'}} onChange={updatePack} type="number" defaultValue={userPacks && userPacks.find(u => u.pack_fkey == pack.id) ? userPacks.find(u => u.pack_fkey == pack.id).quantity : 0} />
                    {false && <div style={{width: '28px', display: 'inline-block', marginRight: '6px'}}>{pack.mm_link && <a className='cart-icon' target="_blank" rel="noopener noreferrer" href={pack.mm_link}><img style={{height: '28px'}} src={MiniatureMarket} /></a>}</div>}
                    <div style={{width: '28px', display: 'inline-block'}}>{pack.amazon_link && <a className='cart-icon' target="_blank" rel="noopener noreferrer" href={pack.amazon_link}><img style={{width: '28px'}} src={Amazon} /></a>}</div>
            </div>
        </div>
    )
}