import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import factions from '../data/factions.json'

const EditUnitListFrench = () => {  
    const [ units, setUnits ] = useState([]) 
   useEffect(() => {
    axios.get('/api/official-units')
    .then(r => setUnits(r.data.filter(u => !u.hidden).sort((a, b) => a.name > b.name ? 1 : -1)))
   }, [])
    return(
        <div>
           {units.map(u => <div key={u.id}><Link to={`/editunittranslations/${u.id}`} key={u.id}>
            {u.name} - {factions.find(f => f.id == u.faction_fkey)?.name} ({u.title}) 
             ({u.name_french && u.image_url_french ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}French&nbsp;
             {u.name_german && u.image_url_german ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}German&nbsp;
            {u.name_spanish && u.image_url_spanish ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}Spanish)
            
            </Link></div>)})
        </div>
    )
}

export default EditUnitListFrench;