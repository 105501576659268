var axios = require('axios');


export const fetchFactions = async () => {
    const res = await axios.get('/api/factions')
    return res.data
}
export const fetchUpgradeTypes = async () => {
    const res = await axios.get('/api/upgrade-types')
    return res.data
}
export const fetchUnitPacks = async () => {
    const res = await axios.get('/api/unitpacks')
    return res.data
}
export const fetchUpgradePacks = async () => {
    const res = await axios.get('/api/upgradepacks')
    return res.data
}
export const fetchConditions = async () => {
    const res = await axios.get('/api/conditions')
    return res.data
}
export const fetchDeployments = async () => {
    const res = await axios.get('/api/deployments')
    return res.data
}
export const fetchObjectives = async () => {
    const res = await axios.get('/api/objectives')
    return res.data
}
export const fetchUpgrades = async () => {
    const res = await axios.get('/api/upgrades')
    return res.data
}
export const fetchPacks = async () => {
    const res = await axios.get('/api/packs')
    return res.data
}

export const fetchUnits = async (faction) => {
    const res = await axios.get(`/api/units/${faction}`)
    return res.data
}
export const fetchCommands = async (faction) => {
    const res = await axios.get(`/api/commands/${faction}`)
    return res.data
}
export const fetchUserPacks = async () => {
    const res = await axios.get(`/api/userpacks`)
    return res.data
}
export const fetchUserUnitTemplates = async () => {
    const res = await axios.get(`/api/userunittemplates`)
    return res.data
}

//module.exports = {fetchFactions, fetchUpgradeTypes, fetchUnitPacks, fetchUpgradePacks, fetchConditions, fetchDeployments, fetchObjectives, fetchUpgrades, fetchPacks, fetchUnits, fetchUserPacks, fetchCommands, fetchUserUnitTemplates}