import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from './navbar.component';
import Cookies from 'universal-cookie';
import { GlobalContext } from "../GlobalContext";
import Comments from './comments.component'
import "../styles/imagePosts.css"
import ImageById from './image-by-id.component';


const ImagePosts = () => {   
    const [ imagePosts, setImagePosts ] = useState([])
    const [ units, setUnits ] = useState([])
    const [ upgrades, setUpgrades ] = useState([])
    const [ faction, setFaction ] = useState(-1)
    const [ requiredUnit, setRequiredUnit ] = useState(-1)
    const [ requiredUpgrade, setRequiredUpgrade ] = useState(-1)
    const [ page, setPage ] = useState(1)
    const [ myLikes, setMyLikes ] = useState([])
    const [ justLiked, setJustLiked ] = useState([])
    const {username, userId, setUserId} = useContext(GlobalContext)
    const pageSize = 12
    
    const likePost = (id) => {
        if(username){
            axios.post('/api/like/' + id)
            .then((r) => {
                fetchLikes()
                if(r.data.liked){
                    var arr = [...justLiked]
                    arr.push(parseInt(id))
                    setJustLiked(arr)
                }
            })
        } else{
            window.location="/login"
        }
    }
    useEffect(() => {
        fetchLikes()
    }, [username])
    const fetchLikes = () => {
        if(username){
            axios.get('/api/my-likes')
            .then(r => {
                setMyLikes(r.data.map(obj => {
                 return obj.image_post_fkey
             }))
            })            
        }
    }
   useEffect(() => {
        const cookies = new Cookies();
        setUserId(cookies.get('id'))
       axios.post('/api/get-image-posts', {unit: requiredUnit, faction: faction, upgrade: requiredUpgrade})
       .then(r => {           
        setImagePosts(r.data)
       })
       axios.get('/api/units')
       .then(r => {
           setUnits(r.data)
       })
       axios.get('/api/figureupgrades')
       .then(r => {
           setUpgrades(r.data)
       })
       fetchLikes()
   }, [])

   useEffect(() => {
    axios.post('/api/get-image-posts', {unit: requiredUnit, upgrade: requiredUpgrade, faction: faction})
    .then(r => {
       setImagePosts(r.data)
    })
   }, [requiredUnit, faction, requiredUpgrade])

    return(
        <div>
            <Navbar />
            <br /><br />
            <br /><br />
            {units.length > 0 &&
            <div className="page-background">
            <div className="filters" style={{textAlign: 'center'}}>
                
                <select className="latest-lists-select" value={requiredUnit} onChange={(e) => setRequiredUnit(e.target.value)}>
                    <option key="-1 unit" value="-1">--Unit--</option>
                    {units.map(u => <option key={`${u.id} unit`}value={u.id}>{u.name}</option>)}
                </select> <span style={{color: 'white'}}>OR</span><br />
                <select className="latest-lists-select" value={requiredUpgrade} onChange={(e) => setRequiredUpgrade(e.target.value)}>
                    <option key="-1 unit" value="-1">--Upgrade/Counterpart--</option>
                    {upgrades.map(u => <option key={`${u.id} upgrade`}value={u.id}>{u.name}</option>)}
                </select>
                {requiredUnit != -1 || requiredUpgrade != -1 &&
                <button onClick={() => {setRequiredUnit(-1); setFaction(-1); setRequiredUpgrade(-1)}}>Clear</button>
                }
            </div>
            <div className="image-posts-page">
            {imagePosts.slice(0, page*pageSize).map(i => (
            <div key={i.id} className="image-post">                
                <Link to={`/viewprofile/${i.user_id}`}><div className="image-post-title" style={{marginBottom: '15px'}}><ImageById style={{borderRadius: '100%', width: '50px', height: '50px', objectFit: 'cover'}} id={i.user_id} type="avatar" />{i.username}</div> </Link>
                <div style={{marginBottom: '5px'}}><span className="image-post-subtitle">{i.title && i.title}{!i.title && "Untitled"} | </span>
                    <span style={{cursor: 'pointer', opacity: myLikes.includes(parseInt(i.id)) ? '1' : '.5'}} onClick={() => likePost(i.id)}>👍({justLiked.includes(parseInt(i.id)) && i.likes + 1}{!justLiked.includes(parseInt(i.id)) && i.likes})</span>   
                </div>   
                
                <ImageById id={i.id} alt={i.title} type="imagepost" />       
                <Comments imagePost={i.id} userId={userId} username={username} />
            </div>
            ))}
            
            </div>

            {page*pageSize < imagePosts.length &&
            <div className="more-button" onClick={() => setPage(page+1)}>▼</div>
            }
            </div>
        }
        </div>
    )
}

export default ImagePosts;