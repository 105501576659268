import React, {useState} from 'react';
import axios from 'axios';
import Navbar from './navbar.component';

const ForgotPassword = () => {
    const [ formEmail, setFormEmail ] = useState('')
    const [ submitted, setSubmitted ] = useState(false)
    const [ response, setResponse ] = useState('')
    const submitReset = (e) => {
        e.preventDefault()
        axios.post('/auth/reset', {email: formEmail})
        .then(r => {
            setSubmitted(true)
            setResponse(r.msg)
            console.log(r)

        })
    }
    return(
        <div>
            <Navbar />
            <br /><br /><br />
            <div className="sub-header">Enter your email address to be sent instructions to reset your password:</div>
        <form style={{textAlign: 'center'}} onSubmit={submitReset}>
            <input value={formEmail} onChange={(e) => setFormEmail(e.target.value)} placeholder="Email" /><br />
            <input type="submit" />
        </form>
        {submitted &&
        <div>
            If an account with this email address exists, an email will be sent with password reset instructions.
            {response}
        </div>
        }

        </div>
    )
}

export default ForgotPassword