import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const EditBattlePlanListTranslations = () => {  
    const [ conditions, setConditions ] = useState([]) 
    const [ deployments, setDeployments ] = useState([]) 
    const [ objectives, setObjectives ] = useState([]) 
   useEffect(() => {
    axios.get('/api/deployments')
    .then(r => setDeployments(r.data.sort((a, b) => a.name > b.name ? 1 : -1)))
    axios.get('/api/conditions')
    .then(r => setConditions(r.data.sort((a, b) => a.name > b.name ? 1 : -1)))
    axios.get('/api/objectives')
    .then(r => setObjectives(r.data.sort((a, b) => a.name > b.name ? 1 : -1)))
   }, [])
    return(
        <div>
            <div className='header'>Deployments</div>
           {deployments.map(u => <div key={u.id}><Link to={`/editdeploymenttranslations/${u.id}`} key={u.id}>{u.name} 
             ({u.name_french && u.image_url_french ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}French&nbsp;
             {u.name_german && u.image_url_german ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}German&nbsp;
            {u.name_spanish && u.image_url_spanish ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}Spanish)</Link></div>)})
            <div className='header'>Conditions</div>
           {conditions.map(u => <div key={u.id}><Link to={`/editconditiontranslations/${u.id}`} key={u.id}>{u.name} 
             ({u.name_french && u.image_url_french ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}French&nbsp;
             {u.name_german && u.image_url_german ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}German&nbsp;
            {u.name_spanish && u.image_url_spanish ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}Spanish)</Link></div>)})
            <div className='header'>Objectives</div>
           {objectives.map(u => <div key={u.id}><Link to={`/editobjectivetranslations/${u.id}`} key={u.id}>{u.name} 
             ({u.name_french && u.image_url_french ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}French&nbsp;
             {u.name_german && u.image_url_german ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}German&nbsp;
            {u.name_spanish && u.image_url_spanish ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}Spanish)</Link></div>)})
        </div>
    )
}

export default EditBattlePlanListTranslations;