import React, {useEffect, useState} from 'react';
import axios from 'axios';

const UpdateUpgradesOwned = (props) => {   
    const [upgrades, setUpgrades] = useState([])
    const [upgradesOwned, setUpgradesOwned ] = useState([])
    const [initialized, setInitialized ] = useState(false)

   useEffect(() => {
    
    axios.get('/api/upgradesowned')
    .then(r => {
        setUpgradesOwned(r.data)
        axios.get('/api/allupgrades-for-collection')
        .then(r => {
            setUpgrades(r.data)
        })
    })
   }, [])

   useEffect(() => {
    if(upgradesOwned.length && upgrades.length && !initialized){
        setInitialized(true)
        var arr = [...upgrades]
        upgradesOwned.map(u => {
            arr[arr.findIndex(i => i.id == u.upgrade_fkey)].owned = u.quantity
        })
        setUpgrades(arr)
    }
   }, [upgradesOwned, upgrades])

   const updateOwned = (id, qty) => {
       var arr = [...upgrades]
       arr[arr.findIndex(i => i.id == id)].owned = qty       
       if(Number.isInteger(parseInt(qty)))
       {
            axios.post('/api/upgradesowned', {upgrade: id, quantity: qty})
       }
       setUpgrades(arr)
   }
    return(
        <div className="pack-info">
            Enter negative or positive numbers to adjust<br />
            <div style={{position: 'absolute', right: '10px', top: '10px', fontSize: '2rem', cursor: 'pointer'}} onClick={() => props.setShowUpgrades(false)}>
                x
            </div>
            <div style={{maxWidth: '450px', margin: 'auto'}}>
           {upgrades.sort((a, b) => a.name > b.name ? 1 : -1).map(u => <div style={{textAlign: 'left'}}><input value={u.owned ? u.owned : 0} type="text" style={{width: '30px'}} onChange={(e) => updateOwned(u.id, e.target.value)} /><label>{u.name}</label></div>)}
           </div>
        </div>
    )
}

export default UpdateUpgradesOwned;