import { useState } from "react"

export default function ImageById({ onClick, alt, id, language, style={}, className, type='unit', version=1, fallback=true }) {
    const [src, setSrc] = useState(`https://d26oqf9i6fvic.cloudfront.net/${typeMap[type]}${language && language !== 'en' ? `${language}/` : ''}${id}.webp?version=${version}`)
    const onError = () => {
        if (fallback) {
            if (src.includes(`/${language}/`)) {
                setSrc(prev => prev.replace(`/${language}/`, '/'))
            } else if (src.includes('new-unit-cards/front')) {            
                setSrc(prev => prev.replace(`new-unit-cards/front`, 'units'))
            } else if (src.includes('upgrades-new')) {            
                setSrc(prev => prev.replace(`upgrades-new`, 'upgrades'))
            }  else if (src.includes('commands-new')) {            
                setSrc(prev => prev.replace(`commands-new`, 'commands'))
            } else{
                setSrc('')
            }
        } else {
            setSrc('')
        }
    }
    if (src) {
        return (
            <img
                onClick={onClick}
                alt={alt ?? ''}
                className={className}
                style={style}
                src={src}                
                onError={() => onError()}
            />
        )
    }
    return (
        <></>
    )
}

const typeMap = {
    'unit': 'units/420/',
    'upgrade': 'upgrades/trimmed/',
    'upgrade-new': 'upgrades-new/trimmed/',
    'command': 'commands/resized/',
    'condition': 'conditions/',
    'deployment': 'deployments/',
    'deployment-back': 'deployment-back/',
    'objective': 'objectives/',
    'upgradeType': 'upgradetypes/',
    'spfront': 'sp-units/trimmed/',
    'spback': 'sp-back/trimmed/',
    'stance1': 'sp-stance1/trimmed/',
    'stance2': 'sp-stance2/trimmed/',
    'imagepost': 'imageposts/',
    'avatar': 'avatars/',
    'unit-front': 'new-unit-cards/front/420/',
    'unit-back': 'new-unit-cards/back/420/',
    'command-new': 'commands-new/resized/',
    'unit-portrait': 'units/portraits/'
}