import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import _ from 'lodash';
import Comments from './comments.component'
import Navbar from './navbar.component';
import { GlobalContext } from "../GlobalContext";
import ImageById from './image-by-id.component';


const ListLink = (props) => {
    switch(props.faction){        
        case 2:
            return <Link to={`/listbuilder/empire/${props.id}`}>{props.name}</Link>
        case 1:
            return <Link to={`/listbuilder/rebel/${props.id}`}>{props.name}</Link>
        case 5:
            return <Link to={`/listbuilder/separatist%20alliance/${props.id}`}>{props.name}</Link>
        case 4:            
            return <Link to={`/listbuilder/galactic%20republic/${props.id}`}>{props.name}</Link>
        case 6:            
            return <Link to={`/listbuilder/mercenary/${props.id}`}>{props.name}</Link>
    }
   }

const Unit = () => {   
   const {id} = useParams()
   const [ unit, setUnit ] = useState({})
   const [ mostPopular, setMostPopular ] = useState({})
   const [ secondMostPopular, setSecondMostPopular ] = useState({})
   const [ thirdMostPopular, setThirdMostPopular ] = useState({})
   const [ fourthMostPopular, setFourthMostPopular ] = useState({})
   const [ lists, setLists ] = useState([])
   const [ imagePosts, setImagePosts ] = useState([])
   const [ keywords, setKeywords ] = useState([])
   const {username, userId} = useContext(GlobalContext)
   useEffect(() => {
        axios.get('/api/unit/' + id)
        .then(r => {
            setUnit(r.data)
        })
        axios.get('/api/latest-lists-with-unit/' + id)
        .then(r => {
            setLists(r.data)
        })
        axios.get('/api/unitkeywords/' + id)
        .then(r => {
            setKeywords(r.data)
        }) 
        axios.post('/api/get-image-posts', {unit: id, upgrade: -1})
        .then(r => setImagePosts(r.data))
        axios.get('/api/most-popular-upgrades/' + id)
        .then(r => {
            var ranked = Object.values(_.groupBy(r.data, 'upgrade_fkey'))
            ranked.sort((a, b) => (a.length < b.length) ? 1 : -1)
            if(ranked.length > 0)
            {
                setMostPopular(ranked[0][0])
                setSecondMostPopular(ranked[1][0])
                setThirdMostPopular(ranked[2][0])
                setFourthMostPopular(ranked[3][0])
            }
        })
   }, [])
   console.log(mostPopular)
   
    return(
        <div>
            <Navbar />
            <br /><br />
            <div className="page-background">
        <div className="unit-page" style={{maxWidth: '900px', margin: 'auto'}}>
            <div className="header" style={{fontSize: '2rem'}}>{unit.name}</div><br />
           <div style={{textAlign: 'center'}}><ImageById id={unit.id} type='unit' alt={unit.name} /></div>
           
           <div style={{textAlign: 'center'}}>{keywords.filter(k => !k.on_card).map(k => <div style={{fontSize: '.9rem'}}><b>{k.name}:</b> {k.description}</div>)}</div>
           <div className="header">Most Common Upgrades</div>
           <div key={mostPopular} style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}className="most-common-upgrades-wrapper">
            {mostPopular.upgrade_fkey && <ImageById style={{width: '225px'}} type='upgrade' id={mostPopular.upgrade_fkey} alt={'most popular'} />}
            {secondMostPopular.upgrade_fkey && <ImageById style={{width: '225px'}} type='upgrade' id={secondMostPopular.upgrade_fkey} alt={'second most popular'} />}
            {thirdMostPopular.upgrade_fkey && <ImageById style={{width: '225px'}} type='upgrade' id={thirdMostPopular.upgrade_fkey} alt={'third most popular'} />}
            {fourthMostPopular.upgrade_fkey &&  <ImageById style={{width: '225px'}} type='upgrade' id={fourthMostPopular.upgrade_fkey} alt={'fourth most popular'} />}
           </div>
           <div className="header">Recent Lists Including {unit.name}</div>
           {lists.map(l => <div style={{textAlign: 'center'}}><ListLink faction={l.faction_fkey} id={l.id} name={l.name}/> by {l.username}</div>)}
           <br />
           {imagePosts.length > 0 &&<div className="header">Community Posts</div>}
           <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
           {imagePosts.slice(0, 5).map(i => <Link to={`/imagepost/${i.id}`}><ImageById style={{width: '425px', maxWidth: '100%'}} type="imagepost" id={i.id} /></Link>)}
           </div>
           <Comments unit={id} userId={userId} username={username} />
           Leave some tips or strategies for this unit!
        </div>
        </div>
        </div>
    )
}

export default Unit;