import React, {useState} from 'react';
import axios from 'axios';
import Navbar from './navbar.component';

const Signup = () => {
    const [ username, setUsername ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ error, setError ] = useState('')
    const submitLogin = (e) => {
        e.preventDefault()
        axios.post('/auth/signup', {username, email, password})
        .then(r => {
            if(r.passed){
                window.location="/login"
            } else{
                setError(r.data.msg)
            }
        })
    }
    return(
        <div>
            <Navbar />
        <br /><br /> 
        <div className="page-background">
                <div className="login-page">
        <div className="header">Create Account</div><br />
        <div>{error}</div>
        <form onSubmit={submitLogin}>
            <input value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" /><br />
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/><br />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"/><br />
            <input type="submit" />
        </form>
        </div></div>
        </div>
    )
}

export default Signup