import { useState, useEffect } from "react"
import axios from "axios"
import getTranslation from "../helpers/getTranslation"

const Keyword = ({ keyword, fewResults }) => {
    const [ expanded, setExpanded ] = useState(fewResults)
    return (
        <div>
        <div><button style={{width: '320px', fontSize: '1.1rem'}} className="pill-button" onClick={() => setExpanded(prev => !prev)}>{keyword.name} {expanded ? '▼': '►'}</button></div>
        {expanded && <div style={{padding: '12px'}}>{keyword.description}</div>}
        </div>
    )
}
export default function Keywords() {
    const [ keywords, setKeywords ] = useState([])
    const [ results, setResults ] = useState([])
    const [ searchTerm, setSearchTerm ] = useState('')    

    useEffect(() => {
        axios.get('/api/keywords')
        .then(r => {
            setKeywords(r.data.filter(k => !k.hide))
            setResults(r.data.filter(k => !k.hide))
        })
    }, [])
    const search = (e) => {
        setSearchTerm(e.target.value)
        setResults(keywords.filter(k => k.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }
    return (
        <div>
                <div className="header">{getTranslation('keywords')}</div>
            <div style={{textAlign: 'center', marginBottom: '12px'}}><input placeholder={`${getTranslation('search')}...`} value={searchTerm} onChange={search} /></div>
            <div key={results.length} style={{textAlign: 'center'}}>
            {results.map(keyword =>
                <Keyword key={keyword.id} keyword={keyword} fewResults={results.length < 4} />
            )}
            </div>
        </div>
    )
}