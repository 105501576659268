import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query'
import axios from 'axios';
import _ from 'lodash';
import Navbar from './navbar.component';
import Cookies from 'universal-cookie';
import Comments from './comments.component'
import {Link} from 'react-router-dom';
import {fetchFactions} from "../helpers/api";
import { GlobalContext } from "../GlobalContext";
import "../styles/latestLists.css";
import ImageById from './image-by-id.component';
   
const ListUnits = (props) => {
    var border = ""
    const faction = props.units[0][0].faction_fkey
    if(faction == 2){
        border +=" empire-border"
    } else if(faction == 1){
        border +=" rebel-border"
    } else if(faction == 4){
        border += " republic-border"
    } else if(faction == 5){
        border += " separatist-border"
    }
    return props.units.sort((a, b) => (a[0].sort_priority > b[0].sort_priority) ? 1 : -1).map(u => {        
        return (
            <div key={`${u[0].list_unit_id}`} className="latest-lists-unit-wrapper">
                 <div style={{cursor: 'pointer'}} onClick={() => props.setImageViewing(u[0].unit_image_url)} className={`latest-lists-unit ${u[0].faction == 6 ? 'mercenary-border' : border}`}><img className={`list-builder-portrait unit-portrait`} src={`https://d26oqf9i6fvic.cloudfront.net/units/portraits/${u[0].unit_id}.webp`} />{u[0].unit_name}{u[0].quantity > 1 && " x" + u[0].quantity}</div>
                 <div className="latest-lists-upgrades-wrapper"><UpgradesDisplay upgrades={u} setImageViewing={props.setImageViewing}/></div>
                 
             </div>
        )
    })
}
const UpgradesDisplay = (props) => {
    return props.upgrades.map((up) => {
        console.log(up)
        if(up.upgrade_name && !up.upgrade_loadout){
            return (
                <div style={{cursor: 'pointer'}} onClick={() => props.setImageViewing(up.upgrade_image_url)} key={`${up.list_unit_id} ${up.upgrade_name}`} className="lastest-lists-upgrade">
                    {up.upgrade_type_image_url && <ImageById style={{width: '30px'}} id={up.upgrade_type_fkey} type='upgradeType' />}
                    {!up.upgrade_type_image_url && <div></div>} {up.upgrade_name}
                </div>
            )
        }
    })
}
const ListLink = (props) => {
 switch(props.faction){        
     case 2:
         return <Link to={`/listbuilder/empire/${props.id}`}>{props.name}</Link>
     case 1:
         return <Link to={`/listbuilder/rebel/${props.id}`}>{props.name}</Link>
     case 5:
         return <Link to={`/listbuilder/separatist%20alliance/${props.id}`}>{props.name}</Link>
     case 4:            
         return <Link to={`/listbuilder/galactic%20republic/${props.id}`}>{props.name}</Link>
    case 6:            
        return <Link to={`/listbuilder/mercenary/${props.id}`}>{props.name}</Link>
 }
}

const ListActivations = (props) => {
     var activations = 0
     props.list.map(u => {
     activations += u[0].quantity
     })
     return <span>{activations} Activations</span>
 }

const LatestLists = () => {   
    const [ lists, setLists ] = useState([])
    const [ units, setUnits ] = useState([])
    const [ page, setPage ] = useState(1)
    const [ imageViewing, setImageViewing ] = useState(-1)
    const [ faction, setFaction ] = useState(-1)
    const [ requiredUnits, setRequiredUnits ] = useState([])
    const [ armySize, setArmySize ] = useState('')
    const [ loaded, setLoaded ] = useState(false)
    const factions = useQuery('factions', fetchFactions).data
    const {username, userId, setUserId, supporter} = useContext(GlobalContext)
    const pageSize = 24
    
    useEffect(() => {

        
        const unitsToInit = () => {

           
            var pwUnits = [{
                type: "bottom_rail"
            }]
            console.log("Ads API is initialized")
            window.ramp.destroyUnits("all").then((function() {
               window.ramp.addUnits(pwUnits).then((function() {
                if (!supporter) {
                    window.ramp.displayUnits()
                }
                }
                ))
           }
           )).catch((function(e) {
              window.ramp.displayUnits()
              console.log(e)
          }
          ))
         }

        const rampInterval = window.setInterval(() => {
            if (typeof window.ramp.addUnits !== 'undefined') {
                window.clearInterval(rampInterval);
                console.log('pushing units')
                if (!supporter) {
                    window.ramp.que.push(unitsToInit)
                }           
            }
        }, 100);
       
    }, [])
    useEffect(() => {
        if (supporter) {
            if (window.ramp?.setPath) {
                console.log('setting path')
                window.ramp?.setPath('patreonOff').then(() =>
                    {
                    console.log('path is set, now destroying units')
                    window.ramp.destroyUnits('all')
                    }
                )
            } else {
                const rampInterval2 = window.setInterval(() => {
                    if (window.ramp?.setPath) {   
                        console.log('set path is defined')                     
                        window.clearInterval(rampInterval2);
                        window.ramp.setPath('patreonOff').then(() =>
                        {
                            console.log('path is set, now destroying units')
                            window.ramp.destroyUnits('all')                        
                        }
                    )
                    }
                }, 100);
                setTimeout(() => {
                    window.clearInterval(rampInterval2)
                }, 5000)
            }
           }
    }, [supporter])
   useEffect(() => {
        const cookies = new Cookies();
        setUserId(cookies.get('id'))
       axios.post('/api/latest-lists', {units: requiredUnits, faction: faction, armySize: armySize})
       .then(r => {
           var groupedByList = Object.values(_.groupBy(r.data, 'id'))
           var groupedByListThenUnit = []
           groupedByList.map(gr => groupedByListThenUnit.push(Object.values(_.groupBy(gr, 'list_unit_id'))))         
           groupedByListThenUnit.sort((a, b) => (a[0].last_upgraded < b[0].last_updated) ? 1 : -1)
           setLists(groupedByListThenUnit)
           setLoaded(true)
       })
       axios.get('/api/units')
       .then(r => {
           setUnits(r.data)
       })
   }, [])

   useEffect(() => {
       if(loaded)
       {
    axios.post('/api/latest-lists', {units: requiredUnits, faction: faction, armySize: armySize})
    .then(r => {
        var groupedByList = Object.values(_.groupBy(r.data, 'id'))
        var groupedByListThenUnit = []
        groupedByList.map(gr => groupedByListThenUnit.push(Object.values(_.groupBy(gr, 'list_unit_id'))))         
        groupedByListThenUnit.sort((a, b) => (a[0].last_upgraded < b[0].last_updated) ? 1 : -1)
        setLists(groupedByListThenUnit)
    })
    }
   }, [requiredUnits, faction, armySize])

   const addUnit = (unit) => {
       setRequiredUnits([...requiredUnits].concat(unit))
   }
   const removeUnit = (un) => {

       const arr = [...requiredUnits]
       setRequiredUnits(arr.filter(u => u!= un))
   }
    return(
        <div>
            <Navbar />
            <br /><br />
            <br /><br />
            {units && units.length > 0 && factions && factions.length > 0 &&
            <div className="page-background">
            <div className="filters" style={{textAlign: 'center'}}>
                <select className="latest-lists-select" value={faction} onChange={(e) => setFaction(e.target.value)}>
                    <option value="-1">--Faction--</option>
                    {factions.filter(f => f.id != 3).map(f => <option value={f.id}>{f.name}</option>)}
                </select>
                <br />
                <select className="latest-lists-select" value={armySize} onChange={(e) => setArmySize(e.target.value)}>
                    <option value="">--Army Size--</option>
                    <option value="Standard">Standard</option>
                    <option value="Skirmish">Skirmish</option>
                    <option value="Grand">Grand</option>
                </select><br />
                <select className="latest-lists-select" value={-1} onChange={(e) => addUnit(e.target.value)}>
                    <option value="-1">--Unit--</option>
                    {faction == -1 && units.map(u => <option value={u.id}>{u.name} {u.title ? '(' + u.title + ')' : ''}</option>)}
                    {faction !== -1 && units.filter(u => !u.hidden && (u.faction_fkey == faction || u.factions?.includes(parseInt(faction)))).map(u => <option value={u.id}>{u.name} {u.title ? '(' + u.title + ')' : ''}</option>)}
                </select>
                {requiredUnits.map(u => <div style={{backgroundColor: 'white', border: '1px solid black', width: '200px', margin: 'auto'}}>{units.find(un => un.id == u).name} <span style={{cursor: 'pointer'}} onClick={() => removeUnit(u)}>X</span></div>)}
                {requiredUnits.length > 0 &&
                <button onClick={() => {setRequiredUnits([]); setFaction(-1); setArmySize('')}}>Clear</button>
                }
            </div>
            <div className="latest-lists-page">
            {lists.slice(0,page*pageSize).map(l => (
            <div key={l[0][0].id} className="latest-lists">
                <img alt="" className="faction-left" src={l[0][0].logo.replace('upload/', 'upload/w_30/')} />
                <img alt="" className="faction-right" src={l[0][0].logo.replace('upload/', 'upload/w_30/')} />
                <div className="latest-list-title"> 
                <Link to={`/viewprofile/${l[0][0].user_id}`}><div className="latest-lists-username"><ImageById style={{borderRadius: '100%', width: '50px', height: '50px', objectFit: 'cover'}} id={l[0][0].user_id} type="avatar" />{l[0][0].username}</div></Link>
                <ListLink faction={l[0][0].faction_fkey} id={l[0][0].id} name={l[0][0].name}/>

                    
                 <div className="latest-lists-points"> {l[0][0].points} points |&nbsp; 
                 <ListActivations list={l} />
                 </div>
                </div>
                <div className="latest-lists-units-wrapper">
                    <ListUnits units={l} setImageViewing={setImageViewing} />
                </div>
                <br /><br />
                    <Comments list={l[0][0].id} userId={userId} username={username} />
            </div>
            ))}
            {imageViewing != -1 &&
            <div style={{textAlign: 'center', paddingTop: '10vh'}} onClick={() => setImageViewing(-1)} className="unit-image-full-screen"><img src={imageViewing} /></div>
            }
            </div>
            {page*pageSize < lists.length &&
            <div className="more-button" onClick={() => setPage(page+1)}>▼</div>
            }
            </div>
        }
        </div>
    )
}

export default LatestLists;