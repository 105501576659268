import React from 'react';
import Navbar from './navbar.component';

const PrivacyPolicy = () => {   
   
    return(
        <div>
        <Navbar />
        <div className="page-background">
            <div className="page" style={{maxWidth: '800px', margin: 'auto'}}>
            <div className="header">Privacy Policy</div>
           <div className="sub-header" style={{marginBottom: '10px'}}><b>Last Updated August 9, 2023</b></div>
           <div className="sub-header">Information Collection</div>
           <div className="body">We collect email addresses if you choose to create an account, for the purpose of account recovery and any other account-related requests. <br />We also use Google Analytics, which gathers device information and general location information in order to provide general usage and visiting statistics, such as what types of devices our users most commonly use, and where most users are located. </div>
           <div className="sub-header">Cookies</div>
           <div className="body">Cookies are stored and read in relation to account verification and Google Analytics. Most web browsers are initially set up to accept cookies. You can reset your web browser to refuse all cookies or to indicate when a cookie is being sent. Please note, however, that certain features of the site might not function if you delete or disable cookies.</div>
           <div className="sub-header">Information Usage</div>
           <div className="body">Your email address is used for account management, and will not be sold or given out for marketing purposes to third parties.</div>
           <div className="sub-header">Information Security</div>
           <div className="body">All passwords are encrypted before being stored. Email addresses are only visible directly in the database, therefore requiring administrative credentials in order to be viewed.</div>
           <div className="sub-header">Personal Data Safety</div>
           <div className="body">We suggest, for any website, including this one, that you use a password unique to that site, elimnating all risk of an effective security compromise.</div>
           <div className="sub-header">Changes to the Privacy Policy</div>
           <div className="body">We may make changes to the privacy policy from time-to-time. Revisit this page at any time if you have any particular concerns.</div>
           <div className="sub-header">Possible Tranfer of Data</div>
           <div className="body">In the event that Tabletop Admiral is purchased or changes hands, all data will of course be transferred as well.</div>
           <div className="sub-header">Contact</div>
           <div className="body">If you have any questions, would like to have your account and therefore email address erased from the database, or have any other concerns, please email tabletopadmiral@gmail.com</div>           
           <div className="sub-header">Advertising</div>
           <div className="body">
           All or partial advertising on this Website or App is managed by Playwire LLC. If Playwire publisher advertising services are used, Playwire LLC may collect and use certain aggregated and anonymized data for advertising purposes. To learn more about the types of data collected, how data is used and your choices as a user, please visit <a href="https://www.playwire.com/privacy-policy">https://www.playwire.com/privacy-policy</a>.</div><br />

           </div></div></div>
    )
}

export default PrivacyPolicy;