import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';

const EditObjective = () => {
    let {id} = useParams()
    const [ name, setName ] = useState('')
    const [ imageSrc, setImageSrc ] = useState('')
    useEffect(() => {
        axios.get('/api/objective/' + id)
        .then(r => {
            setName(r.data.name)
            setImageSrc(r.data.image_url)
        })
    }, [])
    
	const uploadPhoto = async (e) => {
        axios.post("/api/update-objective-image", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
        });
    }

    return(
        <div>
            <div>{name}</div>
            <br /><br />
            <img alt={name} src={imageSrc} />
            <label className="custom-file-upload">
						<input
								className="upload-button"
								type="file"
								name="image"
								onChange={uploadPhoto}
							/>
							Change Image
						</label>
					
       </div>
    )
}

export default EditObjective;