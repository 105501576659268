import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const EditCommandListTranslation = () => {  
    const [ upgrades, setUpgrades ] = useState([]) 
   useEffect(() => {
    axios.get('/api/official-upgrades')
    .then(r => setUpgrades(r.data.sort((a, b) => a.name > b.name ? 1 : -1)))
   }, [])
    return(
        <div>
           {upgrades.map(u => <div key={u.id}><Link to={`/editupgradetranslations/${u.id}`} key={u.id}>{u.name}&nbsp;
             ({u.name_french && u.image_url_french ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}French&nbsp;
             {u.name_german && u.image_url_german ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}German&nbsp;
            {u.name_spanish && u.image_url_spanish ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}Spanish)</Link></div>)})
        </div>
    )
}

export default EditCommandListTranslation;