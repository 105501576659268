import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Navbar from './navbar.component';
import getTranslation from '../helpers/getTranslation';

const NotificationDisplay = (props) => {
    if(props.notification.list_fkey){
        return <div><Link to={`/viewlist/${props.notification.id}`}>{props.notification.name} (List Comment)</Link></div>
    }
    if(props.notification.image_post_fkey){
        if(props.notification.reaction){
            return <div><Link to={`/imagepost/${props.notification.image_post_id}`}>{props.notification.title} (Someone liked your image)</Link></div>
        } else{
            return <div><Link to={`/imagepost/${props.notification.image_post_id}`}>{props.notification.title} (Image Comment)</Link></div>
        }
    }    
    if(props.notification.unit_fkey){
        return <div><Link to={`/unit/${props.notification.unit_fkey}`}>{props.notification.name} (Unit Comment)</Link></div>
    }
}
const Notifications = () => {   
    const [ notifications, setNotifications ] = useState([])

    useEffect(() => {
        axios.get('/api/all-notifications')
        .then(r => setNotifications(r.data))
    }, [])
    return(
        <div>
            <Navbar />
            <br /><br /><br /><br />
        <div style={{textAlign: 'center'}}>
            <div className="header">{getTranslation('notifications')}</div><br />
            {notifications.map(n => (
              <NotificationDisplay notification={n} />
            ))}
        </div>
        </div>
    )
}

export default Notifications;