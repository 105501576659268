import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const EditCommandListTranslation = () => {  
    const [ commands, setCommands ] = useState([]) 
   useEffect(() => {
    axios.get('/api/official-commands')
    .then(r => setCommands(r.data.sort((a, b) => a.name > b.name ? 1 : -1)))
   }, [])
    return(
        <div>
           {commands.map(u => <div key={u.id}><Link to={`/editcommandtranslations/${u.id}`} key={u.id}>{u.name} 
             ({u.name_french ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}French&nbsp;
             {u.name_german ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}German&nbsp;
            {u.name_spanish ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}Spanish)&nbsp;
            {u.name_portuguese ? <span style={{color: 'green'}}>✓</span> : <span style={{color: 'red'}}>x</span>}Portuguese)</Link></div>)})
        </div>
    )
}

export default EditCommandListTranslation;