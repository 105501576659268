import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';
import Cookies from 'universal-cookie';
import Logo from '../images/logo-small-2.png';
import LogoDark from '../images/logo-small.png';
import Menu from '../images/menu.png';
import { GlobalContext } from "../GlobalContext";
import axios from 'axios';
import "../styles/navbar.css"
import getTranslation from '../helpers/getTranslation';

const languages = [
    {
        name: 'English',
        abbreviation: 'en'
    },
    {
        name: 'French',
        abbreviation: 'fr'
    },
    {
        name: 'Spanish',
        abbreviation: 'sp'
    },
    {
        name: 'German',
        abbreviation: 'de'
    },
    {
        name: 'Portuguese',
        abbreviation: 'pt-br'
    },
    {
        name: 'Chinese',
        abbreviation: 'zh'
    },
]

const Navbar = () => {    
    const { username, setUsername, setUserId, language, darkMode, setLanguage, setDarkMode } = useContext(GlobalContext)
    const [ showFactions, setShowFactions ] = useState(false)
    const [ showUserDropdown, setShowUserDropdown ] = useState(false)
    const [ showMobileMenu, setShowMobileMenu ] = useState(false)
    const [ notifications, setNotifications ] = useState([])
    const [ notificationsExpanded ] = useState(false)
    const [ showNotifications, setShowNotifications ] = useState(true)
    const [ showCommunity, setShowCommunity] = useState(false)
    const [ showLanguage, setShowLanguage] = useState(false)
    
  
    useEffect(() => {
        const cookies = new Cookies();
        setUsername(cookies.get('username'))
        setUserId(cookies.get('id'))
        axios.get('/api/notifications')
        .then(r => setNotifications(r.data))
    }, [])

    const logout = () => {
        axios.post('/auth/logout')
        .then(() => {
            setUsername('')
        })
    }

    const clearDropdowns = useCallback(() => {
        console.log('still clearing')
        setShowFactions(false)
        setShowUserDropdown(false)
        setShowCommunity(false)
        setShowLanguage(false)
        setShowMobileMenu(false)
        window.removeEventListener('click', clearDropdowns)
    }, [])
    useEffect(() => {
        if (showMobileMenu || showFactions || showUserDropdown || showLanguage || showCommunity) {
            window.addEventListener('click', clearDropdowns)
        } else {
            window.removeEventListener('click', clearDropdowns)
        }
    }, [showMobileMenu, showFactions, showUserDropdown, showLanguage, showCommunity])
    useEffect(() => {
        if(notifications.length > 0 && notificationsExpanded){
            axios.post('/api/clear-notifications')
        }
    }, [notificationsExpanded])

    const updateLanguage = (e) => {
        localStorage.language = e
        setShowLanguage(false)
        setLanguage(e)
        axios.post('/api/update-language', {language: e})
    }

    const clearNotifications = () => {
        if(notifications.length > 0){
            axios.post('/api/clear-notifications')
        }
        setShowNotifications(false)
    }
    return (
    <div className={`navbar no-print ${darkMode ? 'dark-mode' : ''}`}>
        <div className="navbar-top">
            <a href="/">
                <img alt="logo" src={darkMode ? LogoDark : Logo} width="179" height="35" />
            </a> 
            <div className="nav-item-wrapper">
                <div className="nav-left">
                <div className="nav-item">
                    <div onClick={(e) => { e.stopPropagation(); setShowFactions(!showFactions); setShowUserDropdown(false); setShowCommunity(false); }}>{getTranslation('newArmy', language)} <span className='down-arrow'>▼</span></div>
                    {showFactions &&
                        <div className="nav-dropdown">
                            <a href="/listbuilder/Empire">{getTranslation('empire', language)}</a>
                            <a href="/listbuilder/Rebel">{getTranslation('rebel', language)}</a>
                            <a href="/listbuilder/Separatist Alliance">{getTranslation('separatist', language)}</a>
                            <a href="/listbuilder/Galactic Republic">{getTranslation('republic', language)}</a>
                            <a href="/listbuilder/Mercenary">{getTranslation('mercenary', language)}</a>
                        </div>
                    }
                </div>
                <div className="nav-item">
                    <Link to="/shatterpoint/listbuilder">New Shatterpoint Strike Team</Link>
                </div>
                <div className="nav-item">
                    <div onClick={(e) => { e.stopPropagation(); setShowCommunity(!showCommunity); setShowUserDropdown(false); setShowFactions(false); }}>{getTranslation('legionMisc', language)} <span className='down-arrow'>▼</span></div>
                    {showCommunity &&
                        <div className="nav-dropdown">
                            <Link to="/latest-lists">{getTranslation('latestLists', language)}</Link>
                            <Link to="/imageposts">{getTranslation('imagePosts', language)}</Link>
                            <Link to="/unitposts">{getTranslation('customUnits', language)}</Link>
                            <Link to="/keywords">{getTranslation('keywords', language)}</Link>
                        </div>
                    }
                </div>
                
                </div>
                <div className="nav-right">
                <div style={{textAlign: 'right', marginRight: '16px', fontSize: '12px'}} onClick={(e) => {e.stopPropagation(); setShowLanguage(!showLanguage); clearNotifications(); setShowFactions(false); setShowCommunity(false); }} className="nav-item">
                {language.toUpperCase()}<span className='down-arrow' style={{fontSize: '12px'}}>▼</span>
                {showLanguage &&
                    <div className="nav-dropdown nav-dropdown-right">
                        {languages.map(l => 
                            <div onClick={() => updateLanguage(l.abbreviation)} key={l.name} className="nav-item">{l.name}</div>
                        )}
                    </div>}
                </div>
                
            <div style={{position: 'absolute', fontSize: '1.8rem', right: '12px', top: '5px', cursor: 'pointer', color: darkMode ? '#f3d60e' : '#9a9a9a'}} onClick={() => {setDarkMode(!darkMode); localStorage.darkMode = !darkMode}}><i className="fas fa-moon"></i></div>
                {!username && <div className="nav-item right-most"><Link to={{pathname: "/login", state: {prevUrl: window.location.href}}}>{getTranslation('login', language)}</Link></div>}
                
                {username && <div style={{textAlign: 'right'}} onClick={(e) => {e.stopPropagation(); setShowUserDropdown(!showUserDropdown); clearNotifications(); setShowFactions(false); setShowCommunity(false); }} className="nav-item right-most">
                    {username} <span className='down-arrow'>▼</span>
                    {notifications.length > 0 && showNotifications &&  <div className="notifications-indicator">{notifications.length}</div>}
                    {username && showUserDropdown &&
                    <div className="nav-dropdown nav-dropdown-right">
                    <div className="nav-item"><Link to="/profile/lists">Profile</Link></div>
                        <div className="nav-item"><Link to="/notifications">Notifications {notifications.length > 0 && "("+notifications.length+")"}</Link></div>
                        <div className="nav-item"><Link to="/collection">Collection</Link></div>
                        <div className="nav-item"><Link to="/createimagepost">My Images</Link></div>
                        <div className="nav-item"><Link onClick={logout} to="/login">Log Out</Link></div>
                    </div>        
                }            
                
                </div>}
                </div>
            </div>
            
            
        </div>
        <div className="navbar-mobile">
           
            <div onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <img alt="menu toggle" className="menu-icon" style={{width: '30px', height: 'auto', marginLeft: '5px'}} width="64" height="64" src={Menu} />
             <Link to="/">
                <img alt="logo" width="179" height="35" src={darkMode? LogoDark : Logo} />
            </Link> </div>
            {showMobileMenu &&
            <div className="mobile-dropdown">
            <div className="mobile-nav-item-wrapper">
                
                <div className="nav-item">
                    <div onClick={(e) => { setShowFactions(!showFactions); e.stopPropagation(); }}>▼{getTranslation('newArmy', language)}</div>
                    {showFactions &&
                        <div className="mobile-nav-dropdown">
                            <a href="/listbuilder/Empire">{getTranslation('empire', language)}</a><br />
                            <a href="/listbuilder/Rebel">{getTranslation('rebel', language)}</a><br />
                            <a href="/listbuilder/Separatist Alliance">{getTranslation('separatist', language)}</a><br />
                            <a href="/listbuilder/Galactic Republic">{getTranslation('republic', language)}</a><br />
                            <a href="/listbuilder/Mercenary">{getTranslation('mercenary', language)}</a>
                        </div>
                    }
                </div>
                <div className="nav-item small">
                    <Link to="/keywords">Legion Keywords</Link>
                </div>
                <div className="nav-item small">
                    <Link to="/shatterpoint/listbuilder">New Shatterpoint Strike Team</Link>
                </div>
                <div className="nav-item">
                    <Link to="/latest-lists">{getTranslation('latestLists', language)}</Link>
                </div>
                <div className="nav-item">
                    <Link to="/imageposts">{getTranslation('imagePosts', language)}</Link>
                </div>
                
                {username && <div onClick={(e) => {setShowUserDropdown(!showUserDropdown); clearNotifications(); e.stopPropagation();}} className="nav-item">▼{username} {notifications.length > 0 && <span>({notifications.length})</span>} </div> }   
                {username && showUserDropdown &&
                <div className="mobile-nav-dropdown">
                    <div className="nav-item"><Link to="/profile/lists">Lists & Profile</Link></div>
                    <div className="nav-item"><Link to="/notifications">Notifications {notifications.length > 0 && <span>({notifications.length})</span>}</Link></div>
                    <div className="nav-item"><Link to="/collection">Collection</Link></div>
                    <div className="nav-item"><Link to="/createimagepost">My Images</Link></div>
                    <div className="nav-item"><Link onClick={logout} to="/login">Log Out</Link></div>
                </div>     
                   
                }          
                
                {!username && <div className="nav-item"><Link to="/login">{getTranslation('login', language)}</Link></div>}
                
            </div>
            </div>
        }
            
            <div style={{position: 'absolute', fontSize: '1.8rem', right: '60px', top: '2px', cursor: 'pointer', color: darkMode ? '#f3d60e' : '#9a9a9a'}} onClick={() => {setDarkMode(!darkMode); localStorage.darkMode = !darkMode; }}><i className="fas fa-moon"></i></div>  
            <div style={{textAlign: 'right', marginRight: '16px', fontSize: '12px'}} onClick={() => {setShowLanguage(!showLanguage); clearNotifications(); setShowFactions(false); setShowCommunity(false); }} className="nav-item">
                {language?.toUpperCase() ?? 'en'}<span className='down-arrow' style={{fontSize: '12px'}}>▼</span>
                {showLanguage &&
                    <div className="nav-dropdown nav-dropdown-right">
                        {languages.map(l => 
                            <div onClick={() => updateLanguage(l.abbreviation)} key={l.name} className="nav-item">{l.name}</div>
                        )}
                    </div>}
                </div>
        </div>
    </div>
    )
}

export default Navbar;