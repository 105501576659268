import React, {useState, useEffect} from 'react';
import { Link} from 'react-router-dom';
import axios from 'axios';
import ImageById from './image-by-id.component';
import getTranslation from '../helpers/getTranslation';

const Comments = (props) => {
    const [comments, setComments ] = useState([])
    const fetchComments = () => {
        if(props.list){
            axios.get('/api/comments/' + props.list)
            .then(r => setComments(r.data))
        } else if(props.imagePost){            
            axios.get('/api/image-comments/' + props.imagePost)
            .then(r => setComments(r.data))
        } else if(props.unit){
            axios.get('/api/unit-comments/' + props.unit)
            .then(r => setComments(r.data))
        }
    }
    useEffect(() => { 
       fetchComments()
    }, [])  

   const CommentForm = () => {
        const [text, setText ] = useState('')
        const submitComment = (e) => {
            e.preventDefault()
            if(props.list){
                axios.post('/api/comments/' + props.list, {text})
                .then(() => {
                fetchComments()
                })
            } else if(props.imagePost){
                axios.post('/api/image-comments/' + props.imagePost, {text})
                .then(() => {
                    fetchComments()
                })
            }    else if(props.unit){
                axios.post('/api/unit-comments/' + props.unit, {text})
                .then(() => {
                    fetchComments()
                })
            }             
        }
            return(
            <form className="comment-form" onSubmit={submitComment}>
                <textarea className="comment-input" value={text} onChange={(e) => setText(e.target.value)} type="text" placeholder={getTranslation('comment')} />
                <input className="save-button" type="submit" />
            </form>
        )
    }    

   const CommentsDisplay = () => {
    const deleteComment = (id) => {
        axios.post('/api/delete-comment/' + id)
        .then(() => {
            setComments([...comments.filter(c => c.id != id)])
        })
    }
    if(comments){
        return comments.sort((a, b) => a.last_updated > b.last_updated ? 1 : -1).map(c => {
            const date = new Date(c.last_updated)
            const hour = date.getHours()
            const minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
            const day = date.getDate()
            const month = date.getMonth() + 1
            return(
                <div key={c.id}>
                <div className="comment-wrapper" key={c.id} style={{whiteSpace: 'pre-line', overflowWrap: 'anywhere'}}>
                    <div className="avatar"><ImageById style={{width: '50px', height: '50px', borderRadius: '50%'}} id={c.user_fkey} type="avatar" /></div>
                    <div className="comment-body">
                        <div className="comment-top"><span className="comment-username">{c.username}</span> <span className="comment-time">{month}/{day} at {hour}:{minute}</span></div>
                        <div className="comment-text">{c.text}</div> 
                    </div>
                </div>
                    {c.user_fkey == props.userId && <div className="comment-delete-button" onClick={() => deleteComment(c.id)}>DELETE</div>}
                </div>
            )
        })
    } else{
     return <div></div>
    }
   }


   return(
       <div>
        <CommentsDisplay userId={props.userId}/>
        {props.username &&
            <CommentForm />
        }
        {!props.username &&
            <Link to="/login">Log In to leave a comment!</Link>
        }
       </div>

   )
  
}

export default Comments