import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';

const EditUnitImages = () => {
    let {id} = useParams()
    const [ name, setName ] = useState('')
    const [ frontImageSrc, setFrontImageSrc ] = useState('')
    const [ backImageSrc, setBackImageSrc ] = useState('')
    const [ stance1ImageSrc, setStance1ImageSrc ] = useState('')
    const [ stance2ImageSrc, setStance2ImageSrc ] = useState('')
    useEffect(() => {
        axios.get('/spapi/unit/' + id)
        .then(r => {
            setName(r.data.name)
            setFrontImageSrc(r.data.front_image_url)
            setBackImageSrc(r.data.back_image_url)
            setStance1ImageSrc(r.data.stance1_image_url)
            setStance2ImageSrc(r.data.stance2_image_url)
        })
    }, [])

    const imageTypes = [
        { name: 'front_image_url', update: setFrontImageSrc, src: frontImageSrc, url: '/api/update-shatterpoint-front-image', doctorUrl: '/api/doctor-shatterpoint-front-image/' },
        { name: 'back_image_url', update: setBackImageSrc, src: backImageSrc, url: '/api/update-shatterpoint-back-image', doctorUrl: '/api/doctor-shatterpoint-back-image/'},
        { name: 'stance1_image_url', update: setStance1ImageSrc, src: stance1ImageSrc, url: '/api/update-shatterpoint-stance1-image', doctorUrl: '/api/doctor-shatterpoint-stance1-image/'},
        { name: 'stance2_image_url', update: setStance2ImageSrc, src: stance2ImageSrc, url: '/api/update-shatterpoint-stance2-image', doctorUrl: '/api/doctor-shatterpoint-stance2-image/' },
    ]
    
	// const uploadImage = (e, imageType) => {
	// 	const formData = new FormData();			
	// 	formData.append("file", e.target.files[0]);
	// 	formData.append("upload_preset", "UnitCard");
	// 	axios.post("https://api.cloudinary.com/v1_1/tabletopadmiral/image/upload", formData).then((p) => {
    //         console.log(p)
	// 		const data = {
	// 			id,
	// 			image_url: p.data.secure_url,
	// 		};
	// 		imageTypes.find(t => t.name === imageType).update(p.data.secure_url)
	// 		axios.post("/spapi/update-unit-image", {...data, imageType}).then((r) => {
				
	// 		})
    //         .catch(e => console.log(e));
	// 	})
    //     .catch(e => console.log(e));
	// }
    
	const uploadImage = async (e, imageType) => {
        const type = imageTypes.find(t => t.name === imageType)
        axios.post(type.url, { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`${type.doctorUrl}${id}`)
        });
    }
    return(
        <div>
            <div>{name}</div>
            <br /><br />
            {imageTypes.map(type =>
                <div style={{marginBottom: '24px'}} key={type.name}>
                    <img alt={name} src={type.src} />
                    <label className="custom-file-upload">
                        <input
                                className="upload-button"
                                type="file"
                                name="image"
                                onChange={(e) => uploadImage(e, type.name)}
                            />
                        Change Image ({type.name})
                    </label>
                </div>
            )}
       </div>
    )
}

export default EditUnitImages;