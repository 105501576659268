import React from 'react';
import { Link } from 'react-router-dom';

const HomeFooter = () => {   
   
    return(
        <div className="home-footer">
           <div className="home-footer-item"><a href="mailto:tabletopadmiral@gmail.com">Contact</a></div>
           <div className="home-footer-item"><Link to="/privacy">Privacy Policy</Link></div>
         </div>
    )
}

export default HomeFooter;