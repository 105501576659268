import React, { useEffect, useState, useContext } from 'react';
import Comments from './comments.component'
import {Link} from 'react-router-dom';
import { GlobalContext } from "../GlobalContext";
import Navbar from './navbar.component';
import HomeFooter from './home-footer.component';
import factions from '../data/factions.json';
import axios from 'axios';
import "../styles/homePage.css";
import NewsPost from './home/news-post';
import getTranslation from '../helpers/getTranslation';
import affiliation from '../images/news/affiliations.png'
import ImageById from './image-by-id.component';
import battleforces from '../data/battle-forces.json'

const HomePage = () => {
    const {username, userId, language, supporter, darkMode} = useContext(GlobalContext)
    const [imagePosts, setImagePosts] = useState([])
    const [ myLikes, setMyLikes ] = useState([])
    const [ justLiked, setJustLiked ] = useState([])
    const [ customFactions, setCustomFactions ] = useState([])
    const [ battleForcesViewing, setBattleForcesViewing ] = useState(null)
    useEffect(() => {

        
        const unitsToInit = () => {
            window.ramp.destroyUnits("all").then((function() {
               window.ramp.addUnits(window.pwUnits.concat([{
          
                selectorId: 'first-leaderboard',
        
                type: 'leaderboard_atf'
                }])).then((function() {
                if (!supporter) {
                    window.ramp.displayUnits()
                }
                }
                ))
           }
           )).catch((function(e) {
              window.ramp.displayUnits()
              console.log(e)
          }
          ))
         }

        const rampInterval = window.setInterval(() => {
            if (typeof window.ramp.addUnits !== 'undefined') {
                window.clearInterval(rampInterval);
                if (!supporter) {
                    window.ramp.que.push(unitsToInit)
                }           
            }
        }, 100);

        axios.post('/api/get-home-page-image-posts', {unit: -1, faction: -1, upgrade: -1})
        .then(r => {           
         setImagePosts(r.data.slice(0,3))
        })        
       fetchLikes()
       
    }, [])

    useEffect(() => {
        fetchLikes()
    }, [username])

    useEffect(() => {
        console.log(supporter, 'supporter')
        if (supporter) {
            if (window.ramp?.setPath) {
                console.log('setting path')
                window.ramp?.setPath('patreonOff').then(() =>
                    {
                    console.log('path is set, now destroying units')
                    window.ramp.destroyUnits('all')
                    }
                )
            } else {
                const rampInterval2 = window.setInterval(() => {
                    if (window.ramp?.setPath) {              
                        window.clearInterval(rampInterval2);
                        window.ramp.setPath('patreonOff').then(() =>
                        {
                            window.ramp.destroyUnits('all')                        
                        }
                    )
                    }
                }, 100);
                setTimeout(() => {
                    window.clearInterval(rampInterval2)
                }, 5000)
            }
            axios.get('/api/my-factions')
            .then(r => {
                setCustomFactions(r.data)
            })
           }
    }, [supporter])

    const likePost = (id) => {
        if(username){
            axios.post('/api/like/' + id)
            .then((r) => {
                fetchLikes()
                if(r.data.liked){
                    var arr = [...justLiked]
                    arr.push(parseInt(id))
                    setJustLiked(arr)
                }
            })
        } else{
            window.location="/login"
        }
       
    }
    const fetchLikes = () => {
        if(username){
        axios.get('/api/my-likes')
        .then(r => {
            setMyLikes(r.data.map(obj => {
             return obj.image_post_fkey
         }))
        })            
        }
    }
    return(
        <div className={darkMode && 'dark'}>
            <Navbar />
            <div className="background-glow"></div>
        <br />
        
        <div className="page-background home-background"> 
        <div style={{minHeight: supporter ? 0 : '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} id='first-leaderboard'></div>
            <div className='home-page-wrapper'>
                <div>
                <div>
                    <h1 style={{textAlign: 'center', fontSize: '1.75rem', fontWeight: 'bold', fontVariant: 'small-caps'}}>
                    Tabletop Admiral<br />
                    {getTranslation('homeHeader', language)}
                    </h1>
                    <h2 className="header">{getTranslation('chooseFaction', language)}</h2>
                    <div className="home-page-faction-select">
                        <div style={{textAlign: 'center', position: 'relative'}}>
                        {factions && factions.filter(f => f.name !== "Neutral").concat(customFactions).map(faction => {
                                    return <div style={{ display: 'inline-block'}} key={faction.name}><Link to={`/listbuilder/${faction.user_fkey ? faction.id : faction.name}`} style={{background: faction.color ?? 'none', height: '100%', display: 'inline-block', borderRadius: '50%', padding: faction.color ? '4px' : '0px'}}>
                                        <img className='faction-wrapper' width="75" height={faction.id == 5 ? "66" : "75"} style={{width: '75px', height: faction.id == 5 ? 'auto' : '75px'}} alt={faction.name}
                                            src={faction.user_fkey ? `https://d26oqf9i6fvic.cloudfront.net/custom-faction-top-left/${faction.id}.webp?ver=1` : 
                                            `https://d26oqf9i6fvic.cloudfront.net/factionlogos/${faction.id}.webp?ver=1`} />
                            </Link>                   {battleforces.some(f => f.faction_fkey == faction.id) && 
                                                    <button className="faction-dropdown-button" popovertarget={`faction-${faction.id}`} onClick={() => { setBattleForcesViewing(battleForcesViewing == faction.id ? null : faction.id) }}>
                                                        ▼
                                                    </button>}
                                            {battleForcesViewing == faction.id && <div id={`faction-${faction.id}`} className="faction-dropdown-background">{battleforces.filter(f => f.faction_fkey == faction.id).map(b => <Link style={{display: 'block'}} to={`/listbuilder/${faction.user_fkey ? faction.id : faction.name}/N------bf${b.id}`} >{b.name}</Link>)}</div>}
                                 </div>                      
                        })
                        }</div>
                    </div>
                    <div id="first_med_rect_atf"></div>
                    <div className='header'>{getTranslation('latestNews')}</div>
                    
                    <NewsPost 
                        date='August 10, 2024' 
                        title='Some new UX options and other things to note!' 
                        Body={() => {
                            return (
                                
                                <>
                                I recently moved the "use revamped rules and cards" checkbox to the footer menu, so if you were looking for it, it's there, and again, will not go away.<br />
                                I also added a few UX options that you can find your profile. One is to show your unit and upgrade options as images instead of buttons. This will change the layout of the builder pretty dramatically. Nice for those who don't want to click buttons to view things and just want it all laid out to browse.<br />
                                Another is an option to disable the graying out of units that are not in your collection.<br />
                                Another is an option to turn off the new independent scrollbars for your unit options and your list, for thsoe who don't like it.<br />
                                And lastly, you can choose to not show unit options when you're not using them, on mobile. This is to help with those who didn't like how much scrolling you had to do.<br /><br />
                                Also, I've updated the little portraits in the builder to use the new art, where available, and when you're using the revamped version.<br /><br />
                                More to come as always, and thanks as always for the support.
                                
                                </>
                            )
                        }} /> 
                    <NewsPost 
                        date='July 19, 2024' 
                        title='Newly revealed changes added!*' 
                        Body={() => {
                            return (
                                
                                <>
                                The following have been added, after the latest Ministravaganza reveal of the overhaul of the game:<br />
                                <ul>
                                    <li>New unit card images</li>
                                    <li>New upgrade cards and updated iamges</li>
                                    <li>Updated command card images</li>
                                    <li>Points adjustments</li>
                                    <li>Clone trooper infantry</li>
                                    <li>New upgrade type and associated upgrades</li>
                                </ul>
                                <br />
                                There is a checkbox at the top labeled "Use revamped rules and cards" that will allow you to use the new cards, rules, points, etc.<br />
                                New battle plans, plus changing the standard points from 800 to 1000, will be implemented soon.
                                <br /><br />
                                As always, if you apprecaite the work or just don't want to see ads anymore, consider supporting me at <a href="https://patreon.com/tabletopadmiral">Patreon</a> or with a tip ($12 USD gets you a year with no ads, include your username in the note) <a href="https://paypal.me/tabletopadmiral">paypal.me/tabletopadmiral</a>.<br />
                                <br />
                                And since it's been asked a lot, yes I will be keeping the old version of the game available. You will be able to toggle it at any time.<br />
                                And the custom card makers will gradually have new versions added (old version will still be available). I'll just need some time.

                                </>
                            )
                        }} /> 
                    <NewsPost 
                        date='September 29, 2023' 
                        title='Custom affiliations/multi-faction units!' 
                        Body={() => {
                            return (
                                
                                <>
                                You can now create your own affiliations! The functionality is the same as the custom factions (see 3 posts down), but you can save as an affiliation instead and then create units that are available in multiple factions.<br />
                                Check out this example, created by Theframeinator on Discord:<br />
                                <img src={affiliation} style={{maxWidth: '100%', borderBottom: '2px solid black', marginBottom: '12px'}} /><br />

                                If you want to join and create your own factions, affiliations, units, upgrades, and commands, sign up at <a href="https://patreon.com/tabletopadmiral">Patreon</a> or make a yearly tip of $12 to <a href="https://paypal.me/tabletopadmiral">paypal.me/tabletopadmiral</a> (include your username in the note) to gain access to all the tools.
                               
                                
                                </>
                            )
                        }} /> 
                    <NewsPost 
                        date='September 25, 2023' 
                        title='Modular List Builder Released! (MESBG fans rejoice?)' 
                        Body={() => {
                            return (
                                <>
                                I've been working for a long time to make a modular list builder that can (eventually) take as its input a configuration and data file for any game and spit out a list builder. If you have a game you're interested in (or a game that you're making?) that you'd like a list builder for, maybe contact me in a month or so to talk about it. I'm still ironing out kinks in the current implementation so once the dust settles, I'll be wanting to support as many games as possible.<br />
                                
                                The new list builder lives at <a href='https://modular.tabletopadmiral.com/'>modular.tabletopadmiral.com</a>, but you'll need a data file URL. For now, if you happen to play Middle-Earth Strategy Battle Game, a data file has been made for that by the blog <a href='https://nowforwrath.blogspot.com/'>Now for Wrath</a>, so you'll want to append that data file to the url like this: <a href="https://modular.tabletopadmiral.com?gameUrl=https://nowforwrath.github.io/datav2.json">https://modular.tabletopadmiral.com?gameUrl=nowforwrath.github.io/datav2.json</a>.<br />
                                There will be kinks, but I'm excited about this and I hope those of you that play that game or other soon-to-be-supported games enjoy it.<br />
                                Worth noting that I have nothing to do with the data files. The list builder is game-agnostic and just takes external data files to fill out the list builder. In all cases, you'll have to contact the maker of the data file for data issues, but of course contact me for issues with the builder itself.
                                </>
                            )
                        }} /> 
                </div>
                </div>
            <br />
            <div style={{maxWidth: '98%'}} className="home-page-body">           
            <div className="home-page-image-posts">
                {imagePosts.map(i => 
                    <div key={i.id}>
                        <Link to={`/viewprofile/${i.user_id}`}>
                            <div className="image-post-title">
                                <ImageById style={{borderRadius: '100%', width: '50px', height: '50px', objectFit: 'cover'}} id={i.user_id} type="avatar" />{i.username}
                            </div>
                        </Link>
                        <Link className="image-post-subtitle" to={`/imagepost/${i.id}`}>{i.title}</Link> | 
                        <span style={{cursor: 'pointer', opacity: myLikes.includes(parseInt(i.id)) ? '1' : '.5'}} onClick={() => likePost(i.id)}>👍({justLiked.includes(parseInt(i.id)) && i.likes + 1}{!justLiked.includes(parseInt(i.id)) && i.likes})</span><br />
                        
                        <Link to={`/imagepost/${i.id}`}>
                            <ImageById id={i.id} alt={i.title} type="imagepost" />
                        </Link>
                        <div style={{maxWidth: "950px", margin: "auto"}}>
                            <Comments hideCommentBox={true} imagePost={i.id} userId={userId} username={username} />
                        </div>
                        <br /><br />
                    </div>
                )}
                <Link to="/imageposts">{getTranslation('seeMoreImage')}</Link>
            </div>
            <div style={{textAlign: 'center'}}><Link to="/createimagepost">{getTranslation('postAnImage')}</Link></div>
            </div>
            </div>
        
        </div>
        <HomeFooter />
        <div style={{background: 'white', paddingBottom: '12px'}}>
        <p style={{background: 'white'}}><a href="http://www.playwire.com" rel="noopener" target="_blank"><img src="https://www.playwire.com/hubfs/Powered-by-Playwire-Badges/Ads-Powered-by-playwire-2021-standalone-large-300px.png" alt="Playwire" width="200" loading="lazy" style={{width: '200px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} /></a></p><p style={{textAlign: 'center', background: 'white'}}><a href="https://www.playwire.com/contact-direct-sales" rel="noopener">Advertise on this site.</a></p>
        </div>
        </div>
    )
}

export default HomePage;