import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';
import "../styles/admin.css"

const EditObjectiveTranslations = () => {
    let {id} = useParams()
    const [ nameFrench, setNameFrench ] = useState('')
    const [ imageSrcFrench, setImageSrcFrench ] = useState('')
    const [ englishName, setEnglishName ] = useState('')
    const [ englishTitle, setEnglishTitle ] = useState('')
    const [ englishImageSrc, setEnglishImageSrc ] = useState('')
    const [ nameSpanish, setNameSpanish ] = useState('')
    const [ imageSrcSpanish, setImageSrcSpanish ] = useState('')
    const [ nameGerman, setNameGerman ] = useState('')
    const [ imageSrcGerman, setImageSrcGerman ] = useState('')

    useEffect(() => {
        axios.get('/api/objective/' + id)
        .then(r => {
            setNameFrench(r.data.name_french)
            setImageSrcFrench(r.data.image_url_french)
            setNameSpanish(r.data.name_spanish)
            setImageSrcSpanish(r.data.image_url_spanish)
            setNameGerman(r.data.name_german)
            setImageSrcGerman(r.data.image_url_german)
            setEnglishImageSrc(r.data.image_url)
            setEnglishName(r.data.name)
            setEnglishTitle(r.data.title)
        })
    }, [])

    const submit = () => {
        axios.post('/api/update-objective-translation', {id, name_french: nameFrench, image_url_french: imageSrcFrench, name_spanish: nameSpanish, image_url_spanish: imageSrcSpanish, name_german: nameGerman, image_url_german: imageSrcGerman})
        .then(() => window.location='/editbattleplantranslations')
    }

	const uploadPhoto = (e) => {

		const formData = new FormData();			
		formData.append("file", e.target.files[0]);
		formData.append("upload_preset", "UnitCard");
		axios.post("https://api.cloudinary.com/v1_1/tabletopadmiral/image/upload", formData).then((p) => {
			setImageSrcFrench(p.data.secure_url)
		});
    }
    
	const uploadPhotoSpanish = (e) => {

		const formData = new FormData();			
		formData.append("file", e.target.files[0]);
		formData.append("upload_preset", "UnitCard");
		axios.post("https://api.cloudinary.com/v1_1/tabletopadmiral/image/upload", formData).then((p) => {
			setImageSrcSpanish(p.data.secure_url)
		});
    }
	const uploadPhotoGerman = (e) => {

		const formData = new FormData();			
		formData.append("file", e.target.files[0]);
		formData.append("upload_preset", "UnitCard");
		axios.post("https://api.cloudinary.com/v1_1/tabletopadmiral/image/upload", formData).then((p) => {
			setImageSrcGerman(p.data.secure_url)
		});
    }
    return(
        <div>
            <div className="header">{englishName}</div>
            English Image: <img src={englishImageSrc} /><br />
            English Name: {englishName}<br />
            English Title: {englishTitle}<br />
            <label>French Name: </label>
            <input value={nameFrench} onChange={(e) => setNameFrench(e.target.value)} /><br />
            French Image: {imageSrcFrench && <img style={{maxWidth: '500px'}} alt={nameFrench} src={imageSrcFrench} />}<br /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                />
                Upload French Image
            </label><br /><br />
            
            <label>Spanish Name: </label>
            <input value={nameSpanish} onChange={(e) => setNameSpanish(e.target.value)} /><br />
            Spanish Image: {imageSrcSpanish && <img style={{maxWidth: '500px'}} alt={nameSpanish} src={imageSrcSpanish} />}<br /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoSpanish}
                />
                Upload Spanish Image
            </label><br /><br />

            <label>German Name: </label>
            <input value={nameGerman} onChange={(e) => setNameGerman(e.target.value)} /><br />
            German Image: {imageSrcGerman && <img style={{maxWidth: '500px'}} alt={nameGerman} src={imageSrcGerman} />}<br /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoGerman}
                />
                Upload German Image
            </label><br /><br />
            <button onClick={submit}>Submit</button>
            
            <br /><br />            
       </div>
    )
}

export default EditObjectiveTranslations;