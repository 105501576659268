import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const Verify = () => {
    let { code, id } = useParams();
    const data = {
        id,
        code,
}
    axios.post('/auth/verify', data)
    .then(() => window.location="/login")
	return (
	<div className="container-app">
			
		</div>
	);
};

export default Verify;
