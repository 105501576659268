import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';

const EditCommand = () => {
    let {id} = useParams()
    const [ name, setName ] = useState('')
    useEffect(() => {
        axios.get('/api/command/' + id)
        .then(r => {
            setName(r.data.name)
        })
    }, [])
	
	const uploadPhoto = async (e) => {
        axios.post("/api/update-command-image", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`/api/doctor-command-images/${id}`)
        });
    }
    
	const uploadPhotoNew = async (e) => {
        axios.post("/api/update-command-image-new", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`/api/doctor-command-images-new/${id}`)
        });
    }
    return(
        <div>
            <div>{name}</div>
            <br /><br />
			
            <img alt={name} src={`https://d26oqf9i6fvic.cloudfront.net/commands/resized/${id}.webp`} /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                />
            </label>
            
            <label className="custom-file-upload">
                New
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoNew}
                />
            </label>
       </div>
    )
}

export default EditCommand;