import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import Navbar from './navbar.component';
import Cookies from 'universal-cookie';
import Comments from './comments.component'
import {Link} from 'react-router-dom';
import { GlobalContext } from "../GlobalContext";
import {useParams} from 'react-router-dom'
import "../styles/latestLists.css";
   
const ListUnits = (props) => {
    var border = ""
    const faction = props.units[0][0].faction_fkey
    if(faction == 2){
        border +=" empire-border"
    } else if(faction == 1){
        border +=" rebel-border"
    } else if(faction == 4){
        border += " republic-border"
    } else if(faction == 5){
        border += " separatist-border"
    }
    return props.units.map(u => {        
        return (
            <div key={`${u[0].list_unit_id}`} className="latest-lists-unit-wrapper">
                 <div style={{cursor: 'pointer'}} onClick={() => props.setImageViewing(u[0].unit_image_url)} className={`latest-lists-unit ${border}`}>{u[0].cloudinary_image_url && <img className={`unit-portrait ${border}`} src={u[0].cloudinary_image_url.replace("/upload/", "/upload/w_375/c_crop,g_north_east,h_75,r_max,w_75,x_0/w_45/")} />}{u[0].unit_name}{u[0].quantity > 1 && " x" + u[0].quantity}</div>
                 <div className="latest-lists-upgrades-wrapper"><UpgradesDisplay upgrades={u} setImageViewing={props.setImageViewing}/></div>
                 
             </div>
        )
    })
}
const UpgradesDisplay = (props) => {
    return props.upgrades.map((up) => {
        if(up.upgrade_name && !up.upgrade_loadout){
            return <div style={{cursor: 'pointer'}} onClick={() => props.setImageViewing(up.upgrade_image_url)} key={`${up.list_unit_id} ${up.upgrade_name}`} className="lastest-lists-upgrade">{up.upgrade_type_image_url &&<img alt={up.upgrade_name} className="upgrade-type-icon" src={up.upgrade_type_image_url} />}{!up.upgrade_type_image_url && <div></div>} {up.upgrade_name}</div>
        }
    })
}
const ListLink = (props) => {
 switch(props.faction){        
     case 2:
         return <Link to={`/listbuilder/empire/${props.id}`}>{props.name}</Link>
     case 1:
         return <Link to={`/listbuilder/rebel/${props.id}`}>{props.name}</Link>
     case 5:
         return <Link to={`/listbuilder/separatist%20alliance/${props.id}`}>{props.name}</Link>
     case 4:            
         return <Link to={`/listbuilder/galactic%20republic/${props.id}`}>{props.name}</Link>
 }
}

const ListActivations = (props) => {
     var activations = 0
     props.list.map(u => {
     activations += u[0].quantity
     })
     return <span>{activations} Activations</span>
 }

const ViewList = () => {   
    const [ list, setList ] = useState([])
    const [ units, setUnits ] = useState([])
    const [ imageViewing, setImageViewing ] = useState(-1)
    const {username, userId, setUserId} = useContext(GlobalContext)    
    let {id} = useParams()
    
   useEffect(() => {
        const cookies = new Cookies();
        setUserId(cookies.get('id'))
       axios.get('/api/view-list/' + id)
       .then(r => {
           var groupedByList = Object.values(_.groupBy(r.data, 'id'))
           var groupedByListThenUnit = []
           groupedByList.map(gr => groupedByListThenUnit.push(Object.values(_.groupBy(gr, 'list_unit_id'))))         
           groupedByListThenUnit.sort((a, b) => (a[0].last_upgraded < b[0].last_updated) ? 1 : -1)
           setList(groupedByListThenUnit)
       })
       axios.get('/api/units')
       .then(r => {
           setUnits(r.data)
       })
   }, [])

    return(
        <div>
            <Navbar />
            <br /><br />
            <br /><br />
            {units.length > 0 &&
            <div className="page-background">
            <div className="view-list-page">
            {list.map(l => (
                <div key={l[0][0].id} className="latest-lists view-list">
                    <img alt="" className="faction-left" src={l[0][0].logo.replace('upload/', 'upload/w_30/')} />
                    <img alt="" className="faction-right" src={l[0][0].logo.replace('upload/', 'upload/w_30/')} />
                    <div className="latest-list-title">
                    <ListLink faction={l[0][0].faction_fkey} id={l[0][0].id} name={l[0][0].name}/>

                        
                    <div className="latest-lists-username">{l[0][0].username}</div>
                    <div className="latest-lists-points"> {l[0][0].points} points |&nbsp; 
                    <ListActivations list={l} />
                    </div>
                    </div>
                    <div className="latest-lists-units-wrapper">
                        <ListUnits units={l} setImageViewing={setImageViewing} />
                    </div>
                    <br /><br />
                        <Comments list={l[0][0].id} userId={userId} username={username} />
                </div>
            ))}
            {imageViewing != -1 &&
            <div style={{textAlign: 'center', paddingTop: '10vh'}} onClick={() => setImageViewing(-1)} className="unit-image-full-screen"><img src={imageViewing} /></div>
            }
            </div>

            </div>
        }
        </div>
    )
}

export default ViewList;