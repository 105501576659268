import React, {useEffect, useState} from 'react';
import axios from 'axios';

const UpdateUnitsOwned = (props) => {   
    const [units, setUnits] = useState([])
    const [unitsOwned, setUnitsOwned ] = useState([])
    const [initialized, setInitialized ] = useState(false)

   useEffect(() => {
    
    axios.get('/api/unitsowned')
    .then(r => {
        setUnitsOwned(r.data)
        axios.get('/api/allunits-for-collection')
        .then(r => {
            setUnits(r.data)
        })
    })
   }, [])

   useEffect(() => {
    if(unitsOwned.length && units.length && !initialized){
        setInitialized(true)
        var arr = [...units]
        unitsOwned.map(u => {
            if(arr[arr.findIndex(i => i.id == u.unit_fkey)])
            {
            arr[arr.findIndex(i => i.id == u.unit_fkey)].owned = u.quantity
            } else{
                console.log(u)
            }
        })
        setUnits(arr)
    }
   }, [unitsOwned, units])

   function compare(a, b) {
    if(a.name === b.name){
        if(a.id > b.id){
            return 1
        } else{
            return -1
        }
    } else{
        if(a.name > b.name){
            return 1
        } else{
            return -1
        }
    }
   }

   const updateOwned = (id, qty) => {
       var arr = [...units]
       arr[arr.findIndex(i => i.id == id)].owned = qty       
       if(Number.isInteger(parseInt(qty)))
       {
            axios.post('/api/unitsowned', {unit: id, quantity: qty})
       }
       setUnits(arr)
   }
    return(
        <div className="pack-info">
            Enter negative or positive numbers to adjust<br />
            <div style={{position: 'absolute', right: '10px', top: '10px'}} onClick={() => props.setShowUnits(false)}>
                x
            </div>
            <div style={{maxWidth: '450px', margin: 'auto'}}>
           {units.sort(compare).map(u => <div style={{textAlign: 'left'}}><input value={u.owned ? u.owned : 0} type="text" style={{width: '30px'}} onChange={(e) => updateOwned(u.id, e.target.value)} /><label>{u.name} {u.title && "(" + u.title + ")"}</label></div>)}
           </div>
        </div>
    )
}

export default UpdateUnitsOwned;