import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

const AdminUnitList = () => {
    const [ units, setUnits ] = useState([])
    useEffect(() => {
        axios.get('/api/units')
        .then(r => {
            setUnits(r.data)
        })
       
    }, [])
    return(
        <div>
            {units.map(u => <div><Link to={`/editunit/${u.id}`}>{u.name}</Link></div>)}
       </div>
    )
}

export default AdminUnitList;