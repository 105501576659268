import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from './navbar.component';
import Cookies from 'universal-cookie';
import { GlobalContext } from "../GlobalContext";
import Comments from './comments.component'
import ImageById from './image-by-id.component'
import getTranslation from '../helpers/getTranslation';


const LatestUnits = () => {   
    const [ units, setUnits ] = useState([])
    const [ searchTerm, setSearchTerm ] = useState('')
    const [ page, setPage ] = useState(1)
    const {username, userId, setUserId, supporter} = useContext(GlobalContext)
    const pageSize = 24

   useEffect(() => {
        const cookies = new Cookies();
        setUserId(cookies.get('id'))
       axios.get('/api/get-custom-units')
       .then(r => {           
        setUnits(r.data)
       })
   }, [])

   const addToCollection = (id) => {
       axios.post('/api/add-unit-to-collection/' + id)
       .then(() => window.location.reload())
   }

   const search = () => {
       if (searchTerm) {
        axios.get('/api/search-custom-units/' + searchTerm)
        .then(r => setUnits(r.data))
       } else {           
        axios.get('/api/get-custom-units')
        .then(r => {           
            setUnits(r.data)
        })
       }
   }

    return(
        <div>
            <Navbar />
            <br /><br />
            <br /><br />
            {units.length > 0 &&
            <div className="page-background">
                <div style={{backgroundColor: 'white', textAlign: 'center', paddingTop: '20px', width: '200px', margin: 'auto'}}>
                    <div className='custom-input-wrapper'>
                        <label className='custom-input-label' for='searchTerm'>{getTranslation('search')}</label>
                        <input className='custom-input' placeholder={getTranslation('ieAhsoka')} type='text' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        <button onClick={search} className='upgrade-option' style={{width: '100%'}}>{getTranslation('search')}</button>
                    </div>
                </div>
            <div className="image-posts-page">
            {units.slice(0, page*pageSize).map(u => (
            <div key={u.id} className="image-post">     
            <Link to={`/viewprofile/${u.user_id}`}><div className="image-post-title" style={{marginBottom: '15px'}}><img src={u.avatar && u.avatar.replace('/upload/', '/upload/ar_1:1,c_fill,g_auto,r_max,w_50/')} />{u.username}</div> </Link>  
                <ImageById id={u.id} type='unit' version={u.image_version} />
                <br />
                {supporter &&
                <button onClick={() => addToCollection(u.id)}>Add to my collection</button>
                }
                {!supporter &&
                <div>Become a <a href="https://patreon.com/tabletopadmiral">Patreon</a> supporter to add this unit to your collection and use it within the list builder, or to create your own custom units.</div>
                }
                
                <Comments unit={u.id} userId={userId} username={username} />
            </div>
            ))}
            
            </div>

            {page*pageSize < units.length &&
            <div className="more-button" onClick={() => setPage(page+1)}>▼</div>
            }
            </div>
        }
        </div>
    )
}

export default LatestUnits;