import React from 'react';

const NewsPost = ({ date, title, Body }) => {   
   
    return(
        <div className="home-page-news">
        <div style={{textAlign: 'center', borderBottom: '1px solid black'}}><b>{title}</b></div>
        <div className="sub-header">{date}</div><br />
        <div className="news-body">
        <Body />
        </div>
        <br />
        </div>       
    )
}

export default NewsPost;