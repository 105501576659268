import { useEffect, useContext } from "react"
import Navbar from "./navbar.component"
import { GlobalContext } from "../GlobalContext"
import Keywords from "./keywords.component"

export default function KeywordsPage() {
    const { supporter} = useContext(GlobalContext)
    
    
    useEffect(() => {

        
        const unitsToInit = () => {

           
            var pwUnits = [{
                type: "bottom_rail"
            }]
            window.ramp.destroyUnits("all").then((function() {
               window.ramp.addUnits(pwUnits).then((function() {
                if (!supporter) {
                    window.ramp.displayUnits()
                }
                }
                ))
           }
           )).catch((function(e) {
              window.ramp.displayUnits()
              console.log(e)
          }
          ))
         }

        const rampInterval = window.setInterval(() => {
            if (typeof window.ramp.addUnits !== 'undefined') {
                window.clearInterval(rampInterval);
                console.log('pushing units')
                if (!supporter) {
                    window.ramp.que.push(unitsToInit)
                }           
            }
        }, 100);
       
    }, [])
    useEffect(() => {
        if (supporter) {
            if (window.ramp?.setPath) {
                window.ramp?.setPath('patreonOff').then(() =>
                    {
                    console.log('path is set, now destroying units')
                    window.ramp.destroyUnits('all')
                    }
                )
            } else {
                const rampInterval2 = window.setInterval(() => {
                    if (window.ramp?.setPath) {               
                        window.clearInterval(rampInterval2);
                        window.ramp.setPath('patreonOff').then(() =>
                        {
                            window.ramp.destroyUnits('all')                        
                        }
                    )
                    }
                }, 100);
                setTimeout(() => {
                    window.clearInterval(rampInterval2)
                }, 5000)
            }
           }
    }, [supporter])
    return (
        <div>
            <Navbar />
            <div className="background-glow"></div>
        <br />
        
        <div className="page-background"> 
            <div className="container">
                <Keywords />
            </div>
        </div>
        </div>
    )
}