import React, {useState} from 'react';
import axios from 'axios';
import { useParams } from "react-router";
import Navbar from './navbar.component';

const PasswordReset = () => {
    const [ password, setPassword ] = useState('')
    const {code, id} = useParams()
    const submitPasswordChange = (e) => {
        e.preventDefault()
        axios.post('/auth/set-password', {code, id, password})
        .then(() => {
            window.location="/login"
        })
    }
    return(
        <div>
            <Navbar />
            <br /><br /><br />
        <form onSubmit={submitPasswordChange}>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password"/>
            <input type="submit" />
        </form>

        </div>
    )
}

export default PasswordReset