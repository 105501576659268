import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';
import ImageById from './image-by-id.component';

const EditUpgrade = () => {
    let {id} = useParams()
    const [ name, setName ] = useState('')
    useEffect(() => {
        axios.get('/api/upgrade/' + id)
        .then(r => {
            setName(r.data.name)
        })
    }, [])
	
	const uploadPhoto = async (e) => {
        axios.post("/api/update-upgrade-image", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`/api/doctor-upgrade-images/${id}`)
        });
    }
    
	const uploadPhotoNew = async (e) => {
        axios.post("/api/update-upgrade-image-new", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`/api/doctor-upgrade-images-new/${id}`)
        });
    }
    return(
        <div>
            <div>{name}</div>
            <br /><br />
			
            <ImageById type="upgrade-new" id={id} />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                />
            </label>
            <label className="custom-file-upload">
                NEW
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoNew}
                />
            </label>
       </div>
    )
}

export default EditUpgrade;