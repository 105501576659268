import React, { useEffect, useState, useContext } from 'react';
import {useParams} from 'react-router-dom'
import axios from 'axios';
import Navbar from './navbar.component';
import Cookies from 'universal-cookie';
import { GlobalContext } from "../GlobalContext";
import Comments from './comments.component'
import "../styles/imagePosts.css"
import ImageById from './image-by-id.component';


const ImagePost = () => {   
    const [ imagePost, setImagePost ] = useState({})
    const {username, userId, setUserId} = useContext(GlobalContext)
    let {id} = useParams()
    
   useEffect(() => {
        const cookies = new Cookies();
        setUserId(cookies.get('id'))
        axios.get('/api/get-image-post/' + id)
       .then(r => {           
        setImagePost(r.data)
       })
   }, [])

    return(
        <div>
            <Navbar />
            <br /><br />
            <br /><br />
            <div className="page-background">
            <div className="image-post-page">
           
            <div key={imagePost.id} className="image-post">                
                <div className="image-post-title">{imagePost.title && imagePost.title}{!imagePost.title && "Untitled"}  👍({imagePost.likes}) </div>   
                <div className="image-post-subtitle">{imagePost.username}</div> 
                            <ImageById id={imagePost.id} alt={imagePost.title} type="imagepost" />     
                <Comments imagePost={imagePost.id} userId={userId} username={username} />
            </div>
            
            </div>

            </div>
        </div>
    )
}

export default ImagePost;