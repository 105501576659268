import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';

const EditDeployment = () => {
    let {id} = useParams()
    const [ name, setName ] = useState('')
    const [ imageSrc, setImageSrc ] = useState('')
    useEffect(() => {
        axios.get('/api/deployment/' + id)
        .then(r => {
            setName(r.data.name)
            setImageSrc(r.data.image_url)
        })
    }, [])
    
	const uploadPhoto = (e) => {

		const formData = new FormData();			
		formData.append("file", e.target.files[0]);
		formData.append("upload_preset", "UnitCard");
		axios.post("https://api.cloudinary.com/v1_1/tabletopadmiral/image/upload", formData).then((p) => {
			const data = {
				id,
				image_url: p.data.secure_url,
			};
			setImageSrc(p.data.secure_url)
			axios.post("/api/update-deployment-image", data).then(() => {
				
			});
		});
	}
	
	const uploadPhotoFront = async (e) => {
        axios.post("/api/update-deployment-image-front", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
        });
    }
	
	const uploadPhotoBack = async (e) => {
        axios.post("/api/update-deployment-image-back", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
        });
    }
    return(
        <div>
            <div>{name}</div>
            <br /><br />
            <img alt={name} src={imageSrc} />
            <label className="custom-file-upload">
						<input
								className="upload-button"
								type="file"
								name="image"
								onChange={uploadPhoto}
							/>
							Change Image
						</label>
						
						<label className="custom-file-upload">
							Front
						<input
								className="upload-button"
								type="file"
								name="image"
								onChange={uploadPhotoFront}
							/>
							Change Image
						</label>
						
						<label className="custom-file-upload">
							Back
						<input
								className="upload-button"
								type="file"
								name="image"
								onChange={uploadPhotoBack}
							/>
							Change Image
						</label>
       </div>
    )
}

export default EditDeployment;