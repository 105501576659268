import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';
import "../styles/admin.css"
import ImageById from './image-by-id.component';

const EditUnit = () => {
    let {id} = useParams()
    const [ name, setName ] = useState('')
    const [ unitUpgradeTypes, setUnitUpgradeTypes ] = useState([])
    const [ upgradeTypes, setUpgradeTypes ] = useState([])
    const [ weapons, setWeapons ] = useState([])
    const [ keywords, setKeywords ] = useState([])
    const [ assignedWeapons, setAssignedWeapons ] = useState([])
    const [ assignedKeywords, setAssignedKeywords ] = useState([])

    const fetchUnitWeapons = () => {
        axios.get('/api/unitweapons/' + id)
        .then(r => {
            setAssignedWeapons(r.data)
        })
    }
    const fetchUnitKeywords = () => {        
        axios.get('/api/unitkeywords/' + id)
        .then(r => {
            setAssignedKeywords(r.data)
        })
    }
    useEffect(() => {
        axios.get('/api/unit/' + id)
        .then(r => {
            setName(r.data.name)
            r.data.upgrade_types ? setUnitUpgradeTypes(r.data.upgrade_types) : setUnitUpgradeTypes([]);
        })
        axios.get('/api/upgrade-types')
        .then(r => {
            setUpgradeTypes(r.data)
        })
        fetchUnitWeapons()
        fetchUnitKeywords()
        axios.get('/api/weapons')
        .then(r => setWeapons(r.data))
        axios.get('/api/keywords')
        .then(r => {
            setKeywords(r.data)
        })
    }, [])

    const addUpgradeType = (upgradeId) => {
        const data = {unit: id, upgrade: upgradeId}
        axios.post('/api/unit-upgrade-type', data)
    }
    
    const addKeyword = (keywordId, magnitude=null) => {
        const data = {unit: id, keyword: keywordId, magnitude}
        axios.post('/api/unit-keyword', data)
        .then(() => fetchUnitKeywords())
    }
    const addWeapon = (weaponId) => {
        const data = {unit: id, weapon: weaponId}
        axios.post('/api/unit-weapon', data)
        .then(() => fetchUnitWeapons())
    }

    const removeUpgradeType = (unitUpgradeTypeId) => {
        const data = {unit_upgrade_type: unitUpgradeTypeId}
        axios.post('/api/delete-unit-upgrade-type', data)
    }
    const removeWeapon = (unitWeaponId) => {
        const data = {unit_weapon: unitWeaponId}
        axios.post('/api/delete-unit-weapon', data)
    }
    const removeKeyword = (unitKeywordId) => {
        const data = {unit_keyword: unitKeywordId}
        axios.post('/api/delete-unit-keyword', data)
    }
    
	const uploadPhoto = async (e) => {
        axios.post("/api/update-unit-image", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`/api/doctor-unit-images/${id}`)
        });
    }

    
	const uploadPhotoFront = async (e) => {
        axios.post("/api/update-unit-image-front", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`/api/doctor-unit-images-front/${id}`)
        });
    }

    
	const uploadPhotoBack = async (e) => {
        axios.post("/api/update-unit-image-back", { id} ).then(async (r) => {
            const { url } = r.data
            await axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            await axios.get(`/api/doctor-unit-images-back/${id}`)
        });
    }

    const toggleKeywordOnCard = (id, currentSetting) => {
        axios.post('/api/set-keyword-on-card', {unit_keyword: id, on_card: !currentSetting})
        .then(() => fetchUnitKeywords())
    }
    return(
        <div>
            <div className="header">{name}</div>
            Old
            <div style={{textAlign: 'center'}}>
            <img alt={name} src={`https://d26oqf9i6fvic.cloudfront.net/units/420/${id}.webp`} /><br />
            <ImageById id={id} language="en" type="unit-front" />
            <ImageById id={id} language="en" type="unit-back" />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                />
            </label>

            
            <label className="custom-file-upload">
                Front
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoFront}
                />
            </label>

            
            <label className="custom-file-upload">
                Back
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoBack}
                />
            </label>
                <div className="sub-header">Assigned Upgrade Types:</div>
                {unitUpgradeTypes.map(ut => <div onClick={() => removeUpgradeType(ut.id)}>{upgradeTypes.length > 0 && upgradeTypes.filter(t => t.id == ut.upgrade_type_fkey)[0].name}</div>)}
                <br /><div className="sub-header">Assigned Weapons:</div>
                {assignedWeapons.map(w => <div onClick={() => removeWeapon(w.id)}>{w.name}</div>)}     
                <br /><div className="sub-header">Assigned Keywords:</div>
                {assignedKeywords.map(k => <div><div onClick={() => removeKeyword(k.id)}>{k.name} ({k.magnitude})</div>Defined on card?<input type="checkbox" checked={k.on_card} onChange={() => toggleKeywordOnCard(k.id, k.on_card)} /></div>)}
            
            
            </div>
            
            <br /><br />
            <div className="admin-columns">
            <div>
                <div className="header">Upgrade Types</div>
                {upgradeTypes.map(ut => <div className="admin-add" onClick={() => addUpgradeType(ut.id)}>{ut.name}</div>)}
            </div>
            <div>
                <div className="header">Keywords</div>
                {keywords.map(k => 
                <div className="admin-keyword-wrapper">
                    <span className = {!k.has_magnitude ? "admin-add" : ""} onClick={() => {!k.has_magnitude && addKeyword(k.id)}}>{k.name} </span>
                    {k.has_magnitude && 
                        <span>
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 1)}>1</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 2)}>2</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 3)}>3</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 4)}>4</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 5)}>5</span>      
                        </span>                  
                    }
                </div>)}
            </div>
            <div>
                <div className="header">Weapons</div>
                {weapons.map(w => <div className="admin-add" onClick={() => addWeapon(w.id)}>{w.name}</div>)}
            </div>
            </div>
            
       </div>
    )
}

export default EditUnit;