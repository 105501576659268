import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import Navbar from './navbar.component';
import { GlobalContext } from "../GlobalContext";
import {Link} from 'react-router-dom';
import ImageById from './image-by-id.component';
import getTranslation from '../helpers/getTranslation';

const CreateImagePost = () => {
    const [ title, setTitle ] = useState('')
    const [ units, setUnits ] = useState([])
    const [ unit, setUnit ] = useState(-1)
    const [ upgrade, setUpgrade ] = useState(-1)
    const [ selectedUnits, setSelectedUnits ] = useState([])
    const [ selectedUpgrades, setSelectedUpgrades ] = useState([])
    const [ upgrades, setUpgrades ] = useState([])
    const [ imagePosts, setImagePosts ] = useState([])
    const {username} = useContext(GlobalContext)
    const [ image, setImage ] = useState(null)
    useEffect(() => {
        if(!username){
            window.location = "/login"
        }
       axios.get('/api/units')
       .then(r => {
           setUnits(r.data)
       })
       axios.get('/api/figureupgrades')
       .then(r => {
           setUpgrades(r.data)
       })
       axios.get('/api/my-image-posts')
       .then(r => setImagePosts(r.data))
   }, [])

	const uploadPhoto = (e) => {
        setImage(e.target.files[0])
    }
    const addUnit = (unit) => {
        setSelectedUnits([...selectedUnits].concat(unit))
        setUnit(-1)
    }
    const addUpgrade = (unit) => {
        setSelectedUpgrades([...selectedUpgrades].concat(unit))
        setUpgrade(-1)
    }
    const submit = () => {
        if(image){
            axios.post('/api/image-post', { title, units: selectedUnits, upgrades: selectedUpgrades})
            .then(async r => {
                await axios.put(r.data.imageUrl, image, { headers: { "Content-Type": "multipart/form-data"}})                
                await axios.get(`/api/doctor-imagepost-images/${r.data.id}`)
                window.location.reload()
            })
        }
    }
    const deletePost = (id) => {
        axios.post('/api/delete-image-post/' + id)
        .then(() => {
            axios.get('/api/my-image-posts')
            .then(r => setImagePosts(r.data))
        })
    }
    const removeUnit = (id) => {
        var un = [...selectedUnits]
        un.splice(un.indexOf(id), 1)
        setSelectedUnits(un)
    }
    const removeUpgrade = (id) => {
        var un = [...selectedUpgrades]
        un.splice(un.indexOf(id), 1)
        setSelectedUpgrades(un)
    }
    return(
        <div>
            
            <Navbar />
            <br /><br /><br /><br />
        <div className="page-background" style={{textAlign: 'center'}}>
            <div className="edit-image-page">
                <div>
       <div className="header">Create New Post</div><br />
       <div className="sub-header">Please keep images to figures and terrain from Legion. No general Star Wars content, art, memes, etc. and no direct advertising. A logo in the corner is fine, but don't link to stores or anything in comments or anything like that.<br />Also, don't post custom card images here. There's already a place to view and comment on those.</div>
            <input className="name-input" placeholder="Title..." value={title} onChange={(e)=> setTitle(e.target.value)} />
            <br /><br />
            <div style={{textAlign: 'center'}}>{image && <img className="image-upload-preview" alt="uploaded" src={URL.createObjectURL(image)} />}</div>
            <br />
            
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                />
                Choose File
            </label>
            <br /><br />
            <select className="latest-lists-select" value={unit} onChange={(e) => addUnit(e.target.value)}>
                    <option value="-1">--Units In Image--</option>
                    {units.map(u => <option value={u.id}>{u.name} {u.title ? '(' + u.title + ')' : ''}</option>)}
            </select>
            <select className="latest-lists-select" value={upgrade} onChange={(e) => addUpgrade(e.target.value)}>
                    <option value="-1">--Upgrades/Counterparts In Image--</option>
                    {upgrades.map(u => <option value={u.id}>{u.name}</option>)}
            </select>
            <br />
            Chosen Units and Upgrades:<br /><br />
            {selectedUnits.map(u => <span style={{border: '1px solid black', borderRadius: '5px', padding: '3px', margin: '5px', cursor: 'pointer'}} key= {u} onClick={() => removeUnit(u)}>{units.find(un => un.id == u).name} X</span>)}
            {selectedUpgrades.map(u => <span style={{border: '1px solid black', borderRadius: '5px', padding: '3px', margin: '5px', cursor: 'pointer'}} key= {u} onClick={() => removeUpgrade(u)}>{upgrades.find(un => un.id == u).name} X</span>)}
            <br /><br /><button onClick={submit}>Submit</button>
       </div><br /><br /><br /><br />
       <div className="header">{getTranslation('myPosts')}</div><br />
       <div className="user-image-posts">
            {imagePosts.map(i => <div><Link to={`/imagepost/${i.id}`}>{i.title}</Link><br/><ImageById id={i.id} type="imagepost" /><div><Link to={`/editimagepost/${i.id}`}>Edit</Link> 👍({i.likes}) <span className="comment-delete-button" onClick={() => deletePost(i.id)}>Delete</span></div></div>)}
       </div>
       </div>
        </div>
        </div>
    )
}

export default CreateImagePost;