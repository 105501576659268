
export default [
    {
        name: 'English',
        abbreviation: 'en',
    },
    {
        name: 'French',
        abbreviation: 'fr'
    },
    {
        name: 'Spanish',
        abbreviation: 'sp'
    },
    {
        name: 'German',
        abbreviation: 'de'
    },
    {
        name: 'Portuguese',
        abbreviation: 'pt-br'
    },
    {
        name: 'Chinese',
        abbreviation: 'zh'
    },
]