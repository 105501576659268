import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';
import "../styles/admin.css"
import languages from '../data/languages';
import ImageById from './image-by-id.component';

const CommandLanguage = ({ command, language }) => {
    let {id} = useParams()
    const [ name, setName ] = useState(command?.[`name_${language.name.toLowerCase()}`])

    useEffect(() => {
        setName(command?.[`name_${language.name.toLowerCase()}`])
    }, [command])

    const submit = (e) => {
        e.preventDefault()
        axios.post('/api/update-command-translation', {
            id,
            name,
            language: language.abbreviation,
        })
    }

	const uploadPhoto = async (e, version) => {
        axios.get(`/api/upload-command/${language.abbreviation}/${id}/${version}`)
        .then(async r => {
            const { url } = r.data
            console.log(url)
            axios.put(url, e.target.files[0], { headers: { "Content-Type": "multipart/form-data"}})
            .then(() => {
                axios.get(`/api/doctor-command-images-new/${language.abbreviation}/${id}/${version}`)
                .then(() => {
                    window.location.reload()
                })
                .catch(e => console.log(e))
            })
            .catch(e => console.log(e))
        })
    }    
    return(
        <div>     
            <b>{language.name}</b>
            <input value={name} onChange={(e) => setName(e.target.value)} />
            <button onClick={submit}>Submit</button><br />
            
            <br />
            <label className="custom-file-upload">
                Upload Image (Old)
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={e => uploadPhoto(e, 1)}
                />
            </label>
            <label className="custom-file-upload">
                Upload Image (New)
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={e => uploadPhoto(e, 2)}
                />
            </label><br /><br />
            <div className="flex">
                <div>
                    <div>Old:</div>
                    <ImageById style={{width: '225px'}} fallback={false} id={id} language={language.abbreviation} version={1} type="command" />
                </div>
                <div>
                    <div>New:</div>
                    <ImageById style={{width: '225px'}} fallback={false} id={id} language={language.abbreviation} version={1} type="command-new" />
                </div>
                </div>
       </div>
    )
}

const EditCommandTranslation = () => {
    let {id} = useParams()
    const [ command, setCommand ] = useState(null)

    useEffect(() => {
        axios.get('/api/command/' + id)
        .then(r => {
            setCommand(r.data)
        })
    }, [])
    return (
        <div>
            <b>English</b>: {command?.name}<br />            
            <ImageById style={{width: '225px'}} fallback={false} id={id} language="en" version={1} type="command" />
            <ImageById style={{width: '225px'}} fallback={false} id={id} language="en" version={1} type="command-new" />
        {languages.filter(l => l.name !== 'English').map(l => <CommandLanguage command={command} language={l} />)}
        </div>
    )
}

export default EditCommandTranslation;