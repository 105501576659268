import translations from '../data/translations.json'
const languageMap = {
    en: 'English',
    fr: 'French',
    sp: 'Spanish',
    'pt-br': 'Portuguese',
    'zh': 'Chinese',
}
export default function getTranslation(code, language){
    const t = translations.find(t => t.Code === code)
    if (t?.[languageMap?.[language]]) {
        return t[languageMap[language]]
    } else if (t) {
        return t?.English
    } else {
        return code
    }
}