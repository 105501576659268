import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';
import "../styles/admin.css"

const EditWeapon = () => {
    let {id} = useParams()
    const [ weapon, setWeapon ] = useState({})
    const [ keywords, setKeywords ] = useState([])
    const [ assignedKeywords, setAssignedKeywords ] = useState([])

    useEffect(() => {
        axios.get('/api/weapon/' + id)
        .then(r => setWeapon(r.data))
        fetchWeaponKeywords()
        fetchAssignedKeywords()
    }, [])

    const fetchWeaponKeywords = () => {
        axios.get('/api/weapon-keywords')
        .then(r => {
            setKeywords(r.data)
        })
    }

    const fetchAssignedKeywords = () => {
        axios.get('/api/weapon-weapon-keywords/' + id)
        .then(r => {
            setAssignedKeywords(r.data)
        })
    }
    const addKeyword = (keywordId, magnitude=null) => {
        const data = {keyword: keywordId, weapon: id, magnitude: magnitude}
        axios.post('/api/weapon-keyword', data)
        .then(() => fetchWeaponKeywords())
    }

    const removeKeyword = () => {

    }
    return(
        <div>
            <div className="header">{weapon.name}</div>
            
            <div style={{textAlign: 'center'}}>
                <br /><div className="sub-header">Assigned Keywords:</div>
                {assignedKeywords.map(k => <div onClick={() => removeKeyword(k.id)}>{k.name} ({k.magnitude})</div>)}
            
            
            </div>
            
            <br /><br />
            <div className="admin-columns">
            <div>
                <div className="header">Keywords</div>
                {keywords.map(k => 
                <div className="admin-keyword-wrapper">
                    <span className = {!k.has_magnitude ? "admin-add" : ""} onClick={() => {!k.has_magnitude && addKeyword(k.id)}}>{k.name} </span>
                    {k.has_magnitude && 
                        <span>
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 1)}>1</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 2)}>2</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 3)}>3</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 4)}>4</span> 
                            <span style={{border: '1px solid black', cursor: 'pointer', padding: '0px 5px 0px 5px'}} onClick={() => addKeyword(k.id, 5)}>5</span>      
                        </span>                  
                    }
                </div>)}
            </div>
            </div>
            
       </div>
    )
}

export default EditWeapon;