import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import axios from 'axios';
import "../styles/admin.css"

const EditUnitTranslation = () => {
    let {id} = useParams()
    const [ nameFrench, setNameFrench ] = useState('')
    const [ titleFrench, setTitleFrench ] = useState('')
    const [ englishName, setEnglishName ] = useState('')
    const [ englishTitle, setEnglishTitle ] = useState('')
    const [ englishImageSrc, setEnglishImageSrc ] = useState('')
    const [ nameSpanish, setNameSpanish ] = useState('')
    const [ titleSpanish, setTitleSpanish ] = useState('')
    const [ nameGerman, setNameGerman ] = useState('')
    const [ titleGerman, setTitleGerman ] = useState('')
    const [ spanishImage, setSpanishImage ] = useState('')
    const [ germanImage, setGermanImage ] = useState('')
    const [ frenchImage, setFrenchImage ] = useState('')

    useEffect(() => {
        axios.get('/api/unit/' + id)
        .then(r => {
            setNameFrench(r.data.name_french)
            setTitleFrench(r.data.title_french)
            setNameSpanish(r.data.name_spanish)
            setTitleSpanish(r.data.title_spanish)
            setNameGerman(r.data.name_german)
            setTitleGerman(r.data.title_german)
            setEnglishImageSrc(r.data.image_url)
            setEnglishName(r.data.name)
            setEnglishTitle(r.data.title)
        })
    }, [])

    const submit = (e) => {
        e.preventDefault()
        const needFrenchUrl = Boolean(frenchImage)
        const needGermanUrl = Boolean(germanImage)
        const needSpanishUrl = Boolean(spanishImage)
        axios.post('/api/update-unit-translation', {
            id,
            name_french: nameFrench,
            needFrenchUrl,
            needGermanUrl,
            needSpanishUrl,
            title_french: titleFrench,
            name_spanish: nameSpanish,
            title_spanish: titleSpanish,
            name_german: nameGerman,
            title_german: titleGerman,
        })
        .then(async r => {
            const { frenchUrl, spanishUrl, germanUrl } = r.data
            if (frenchUrl) {                
                await axios.put(frenchUrl, frenchImage, { headers: { "Content-Type": "multipart/form-data"}})
            }
            if (germanUrl) {                
                await axios.put(germanUrl, germanImage, { headers: { "Content-Type": "multipart/form-data"}})
            }
            if (spanishUrl) {                
                await axios.put(spanishUrl, spanishImage, { headers: { "Content-Type": "multipart/form-data"}})
            }
            await axios.post('/api/convert-translated-images', { id, spanish: needSpanishUrl, french: needFrenchUrl, german: needGermanUrl})
        })
    }

	const uploadPhotoFrench = (e) => {
        setFrenchImage(e.target.files[0])
    }    
	const uploadPhotoSpanish = (e) => {
        setSpanishImage(e.target.files[0])
    }
	const uploadPhotoGerman = (e) => {
        setGermanImage(e.target.files[0])
    }
    return(
        <div>
            <div className="header">{englishName}</div>
            English Image: <img src={englishImageSrc} /><br />
            English Name: {englishName}<br />
            English Title: {englishTitle}<br />
            <label>French Name: </label>
            <input value={nameFrench} onChange={(e) => setNameFrench(e.target.value)} /><br />
            <label>French Title: </label>
            <input value={titleFrench} onChange={(e) => setTitleFrench(e.target.value)} /><br />
            French Image: {(frenchImage) && <img style={{maxWidth: '500px'}} alt={nameFrench} src={URL.createObjectURL(frenchImage)} />}<br /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoFrench}
                />
                Upload French Image
            </label><br /><br />
            
            <label>Spanish Name: </label>
            <input value={nameSpanish} onChange={(e) => setNameSpanish(e.target.value)} /><br />
            <label>Spanish Title: </label>
            <input value={titleSpanish} onChange={(e) => setTitleSpanish(e.target.value)} /><br />
            Spanish Image: {(spanishImage) && <img style={{maxWidth: '500px'}} alt={nameSpanish} src={URL.createObjectURL(spanishImage)} />}<br /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoSpanish}
                />
                Upload Spanish Image
            </label><br /><br />
            
            <label>German Name: </label>
            <input value={nameGerman} onChange={(e) => setNameGerman(e.target.value)} /><br />
            <label>German Title: </label>
            <input value={titleGerman} onChange={(e) => setTitleGerman(e.target.value)} /><br />
            German Image: {(germanImage) && <img style={{maxWidth: '500px'}} alt={nameGerman} src={URL.createObjectURL(germanImage)} />}<br /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhotoGerman}
                />
                Upload German Image
            </label><br /><br />
            <button onClick={submit}>Submit</button>
            
            <br /><br />            
       </div>
    )
}

export default EditUnitTranslation;