import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';


const MissingImages = () => {
    const [ upgrades, setUpgrades ] = useState([])
    useEffect(() => {
        axios.get('/api/upgrades')
        .then(r => {
            console.log(r)
            setUpgrades(r.data)
        })
    }, [])
    return(
        <div>
            {upgrades.map(u => <img src={u.image_url} />)}
           {upgrades.map(u => <div><Link to={`/editupgrade/${u.id}`}>{u.name}</Link></div>)}    
        </div>
    )
}

export default MissingImages;