import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Navbar from './navbar.component';
import { useParams } from "react-router";

const EditImagePost = () => {
    const [ title, setTitle ] = useState('')
    const [ imageSrc, setImageSrc ] = useState('')
    const [ units, setUnits ] = useState([])
    const [ unit, setUnit ] = useState(-1)
    const [ upgrade, setUpgrade ] = useState(-1)
    const [ selectedUnits, setSelectedUnits ] = useState([])
    const [ selectedUpgrades, setSelectedUpgrades ] = useState([])
    const [ upgrades, setUpgrades ] = useState([])
    let {id} = useParams()
    useEffect(() => {
       axios.get('/api/units')
       .then(r => {
           setUnits(r.data)
       })
       axios.get('/api/figureupgrades')
       .then(r => {
           setUpgrades(r.data)
       })
       axios.get('/api/image-post/' + id)
       .then(r => {
           var temp = []
           setTitle(r.data.title)
           setImageSrc(r.data.image_url)
           r.data.units.map(u => temp.push(u.unit_fkey))
           setSelectedUnits(temp)
       })
   }, [])

	const uploadPhoto = (e) => {
		const formData = new FormData();			
		formData.append("file", e.target.files[0]);
		formData.append("upload_preset", "Painting");
		axios.post("https://api.cloudinary.com/v1_1/tabletopadmiral/image/upload", formData).then((p) => {
            setImageSrc(p.data.secure_url)		
		});
    }
    const addUnit = (unit) => {
        setSelectedUnits([...selectedUnits].concat(unit))
        setUnit(-1)
    }
    const addUpgrade = (unit) => {
        setSelectedUpgrades([...selectedUpgrades].concat(unit))
        setUpgrade(-1)
    }
    const submit = () => {
        axios.post('/api/update-image-post/' + id, {image_url: imageSrc, title, units: selectedUnits, upgrades: selectedUpgrades})
        .then(() => window.location = "/createimagepost")
    }
    const removeUnit = (id) => {
        var un = [...selectedUnits]
        un.splice(un.indexOf(id), 1)
        setSelectedUnits(un)
    }
    const removeUpgrade = (id) => {
        var un = [...selectedUpgrades]
        un.splice(un.indexOf(id), 1)
        setSelectedUpgrades(un)
    }
    return(
        <div>
            
            <Navbar />
            <br /><br /><br /><br />
            <div className="page-background" style={{textAlign: 'center'}}>
                <div className="edit-image-page">
       <div className="header">Edit Image Post</div><br />
            <input className="name-input" placeholder="Title..." value={title} onChange={(e)=> setTitle(e.target.value)} />
            <br /><br />
            <label className="custom-file-upload">
                <input
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                    />
			</label><br /><br />
            <div style={{textAlign: 'center'}}>{imageSrc && <img className="image-upload-preview" alt="uploaded" src={imageSrc} />}</div>
            <br />
            <br /><br />
            <select className="latest-lists-select" value={unit} onChange={(e) => addUnit(e.target.value)}>
                    <option value="-1">--Units In Image--</option>
                    {units.map(u => <option key={`${u.id} option`} value={u.id}>{u.name}</option>)}
            </select>
            <select className="latest-lists-select" value={upgrade} onChange={(e) => addUpgrade(e.target.value)}>
                    <option value="-1">--Upgrades/Counterparts In Image--</option>
                    {upgrades.map(u => <option value={u.id}>{u.name}</option>)}
            </select><br /><br />
            Chosen Units:<br /><br />
            {units.length > 0 && selectedUnits.map(u => <span style={{border: '1px solid black', borderRadius: '5px', padding: '3px', margin: '5px', cursor: 'pointer'}} key= {u} onClick={() => removeUnit(u)}>{units.find(un => un.id == u).name} X</span>)}
            {selectedUpgrades.map(u => <span style={{border: '1px solid black', borderRadius: '5px', padding: '3px', margin: '5px', cursor: 'pointer'}} key= {u} onClick={() => removeUpgrade(u)}>{upgrades.find(un => un.id == u).name} X</span>)}
            <br /><br /><button onClick={submit}>Submit</button>
       </div><br /><br />
        </div>
        </div>
    )
}

export default EditImagePost;